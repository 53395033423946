import { selector } from "recoil";

import { azureConfigurationForUserAtom } from "../atoms";

/**
 * If a user have no fournisseur defined in the department field,
 * then the default value from tech pro is used
 */
export const fournisseurOrDefaultFolderNameSelector = selector<string>({
  key: "fournisseurOrDefaultFolderNameSelector",
  get: ({ get }) => {
    const azureConfigurationForUser = get(azureConfigurationForUserAtom);

    if (azureConfigurationForUser?.userFournisseur) {
      return azureConfigurationForUser?.userFournisseur;
    }
    const defaultFolder = azureConfigurationForUser?.defaultFolderNameIfFournisseurIsNotSet;
    console.debug(
      `[fournisseurOrDefaultFolderNameSelector] fournisseur is empty, default folder will be used : ${defaultFolder}.`
    );
    return defaultFolder;
  }
});
