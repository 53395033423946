import { Step, StepLabel, Stepper } from "@mui/material";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import sanitizeHtml from "sanitize-html";

import {
  COLAS_YELLOW_WITH_A_LITTLE_TRANSPARENCY,
  RESPONSIVE_BREAK_POINT_IN_PX,
  RESPONSIVE_VERY_SMALL_DEVICES_BREAK_POINT_IN_PX,
} from "../../colasTheme";
import { ApplicationStep } from "../../providers/applicationStep/atoms";
import { isUploadInProgressAtom } from "../../providers/azure/atoms";
import StepperButton, { BUTTON_DIRECTION } from "../StepperButton";
import { FC } from "react";

export type CustomStepperProps = {
  currentApplicationStepIndex: number;
  applicationSteps: ApplicationStep[];
  isCurrentStepValid: boolean;
  isStepperEnded: boolean;
  onPreviousApplicationStep: () => void;
  onNextApplicationStep: () => void;
};

/**
 * Component to navigate between different steps
 */
const ApplicationStepper: FC<CustomStepperProps> = ({
  currentApplicationStepIndex,
  applicationSteps,
  isCurrentStepValid,
  isStepperEnded,
  onPreviousApplicationStep,
  onNextApplicationStep,
}) => {
  console.debug("ApplicationStepper");
  const { t } = useTranslation();
  const isUploadInProgress = useRecoilValue(isUploadInProgressAtom);

  return (
    <Box
      className={"ApplicationStepper"}
      sx={{
        width: "100%",
        backgroundColor: COLAS_YELLOW_WITH_A_LITTLE_TRANSPARENCY,
        padding: "1rem 5rem 1rem 5rem",
        [`@media (max-width: ${RESPONSIVE_BREAK_POINT_IN_PX}px)`]: {
          padding: "1rem",
        },
      }}
    >
      <Box>
        <Stepper
          activeStep={currentApplicationStepIndex}
          sx={{
            // Steps labels are hidden on very small devices since there is not enough space...
            [`@media (max-width: ${RESPONSIVE_VERY_SMALL_DEVICES_BREAK_POINT_IN_PX}px)`]: {
              "& .MuiStepLabel-labelContainer": { display: "none" },
            },
          }}
        >
          {applicationSteps?.map((applicationStep) => {
            return (
              <Step key={applicationStep?.name}>
                <StepLabel>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(applicationStep?.name, {
                        selfClosing: ["br"],
                      }),
                    }}
                  />
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
      <Box
        className={"CurrentApplicationStep"}
        sx={{
          marginTop: "2rem",
          display: "grid",
          gridTemplateColumns: "",
          gridTemplateRows: "",
          gap: "0px 0px",
          gridTemplateAreas: `
            'previousStepButton nextStepButton'
            '. .'`,
          padding: "0 6vw 0 6vw",
        }}
      >
        {/* Always display previous button, since on the first step,
            the previous button will bring back to the create or update panel */}
        <Box
          sx={{
            gridArea: "previousStepButton",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <StepperButton
            direction={BUTTON_DIRECTION.PREVIOUS}
            label={t("upload-page-stepper-previous-button")}
            onClick={onPreviousApplicationStep}
            disabled={isStepperEnded || isUploadInProgress}
          />
        </Box>
        {currentApplicationStepIndex < applicationSteps?.length - 1 ? (
          <Box
            sx={{
              gridArea: "nextStepButton",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <StepperButton
              direction={BUTTON_DIRECTION.NEXT}
              label={applicationSteps[currentApplicationStepIndex]?.validateStepButton}
              onClick={onNextApplicationStep}
              disabled={!isCurrentStepValid}
            />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default ApplicationStepper;
