import React, { FC } from "react";

import { Box, Container } from "@mui/material";
import { UploadFile } from "../../providers/azure/atoms";
import { COLAS_BLACK_LIGHT } from "../../colasTheme";
import { formatBytesToReadableValueWithUnit } from "../../utils/conversion";

export type FilenameWithSizeProps = {
  uploadFile: UploadFile | undefined;
};

/**
 * Component to display the upload file filename with its size on a second line
 */
const FilenameWithSize: FC<FilenameWithSizeProps> = ({ uploadFile }) => {
  if (!uploadFile) {
    return null;
  }

  return (
    <Container className={"FilenameWithSize"} sx={{ margin: 0, padding: 0 }}>
      <Box sx={{ "& p": { margin: 0 } }}>
        <p>{uploadFile?.file?.name}</p>
      </Box>
      <Box sx={{ fontSize: "0.7rem", color: COLAS_BLACK_LIGHT }}>
        {formatBytesToReadableValueWithUnit(uploadFile?.file?.size)}
      </Box>
    </Container>
  );
};

export default FilenameWithSize;
