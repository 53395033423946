import { FIELD_TYPES, IndexationField } from "../../../models/IndexationField";
import { FileType, UploadFile } from "../../azure/atoms";
import {
  ComputeIndexationFileBodyRequestParameterDTO,
  ComputeIndexationFileIndexationFieldType,
  ComputeIndexationFileProxyUploadFileType,
} from "../models/computeIndexationFileBodyRequestParameterDTO";

function mappIndexationFieldToComputeIndexationFileIndexationFieldDTO(
  indexationField: IndexationField,
): ComputeIndexationFileIndexationFieldType {
  let values: string[];
  if (Array.isArray(indexationField?.value)) {
    if (indexationField?.type === FIELD_TYPES.THESAURUS) {
      // The indexation service used by the script handle thesaurus ids with this format
      values = indexationField?.value?.map((currentValue) => `#${currentValue?.id}`);
    } else {
      values = indexationField?.value?.map((currentValue) => `${currentValue?.label}`);
    }
  } else if (!!indexationField?.value) {
    values = [indexationField?.value];
  } else {
    values = [];
  }
  return {
    internalName: indexationField?.internalName as string,
    values: values as string[],
  };
}

export function computeIndexationFileBodyRequestParameterDTO(
  reportageGlobalIndexationData: IndexationField[],
  uploadFiles: UploadFile[],
  reportageCode: string | undefined,
  reportageObservations: string | undefined,
): ComputeIndexationFileBodyRequestParameterDTO {
  const proxyUploadFiles = uploadFiles?.filter(
    (currentUploadFile) => currentUploadFile?.fileType === FileType.PROXY,
  );

  const proxyUploadFilesMappedForDTO: ComputeIndexationFileProxyUploadFileType[] =
    proxyUploadFiles?.map((currentProxyUploadFile) => {
      // Proxy filename is always pressent
      let currentProxyUploadFileMappedForDTO: ComputeIndexationFileProxyUploadFileType = {
        filename: currentProxyUploadFile?.file?.name,
      };

      if (!!currentProxyUploadFile?.mappedMasterFile?.file?.name) {
        currentProxyUploadFileMappedForDTO = {
          ...currentProxyUploadFileMappedForDTO,
          mappedMasterFilename: currentProxyUploadFile?.mappedMasterFile?.file?.name,
        };
      }

      if (!!currentProxyUploadFile?.proxyIndexationData) {
        const currentProxyIndexationMappedForDTO: ComputeIndexationFileIndexationFieldType[] =
          currentProxyUploadFile?.proxyIndexationData?.map((currentProxyIndexationData) => {
            return mappIndexationFieldToComputeIndexationFileIndexationFieldDTO(
              currentProxyIndexationData,
            );
          });

        if (!!currentProxyUploadFile?.proxyMediaId) {
          currentProxyUploadFileMappedForDTO = {
            ...currentProxyUploadFileMappedForDTO,
            proxyMediaId: currentProxyUploadFile?.proxyMediaId,
          };
        }

        currentProxyUploadFileMappedForDTO = {
          ...currentProxyUploadFileMappedForDTO,
          proxyIndexation: currentProxyIndexationMappedForDTO,
        };
      }

      return currentProxyUploadFileMappedForDTO;
    });

  let computedComputeIndexationFileBodyRequestParameterDTO: ComputeIndexationFileBodyRequestParameterDTO =
    { uploadFiles: proxyUploadFilesMappedForDTO };

  // Add global indexation data if present
  if (!!reportageGlobalIndexationData) {
    const reportageGlobalIndexationFieldsMappedForDTO: ComputeIndexationFileIndexationFieldType[] =
      reportageGlobalIndexationData?.map((currentGlobalIndexationField) => {
        return mappIndexationFieldToComputeIndexationFileIndexationFieldDTO(
          currentGlobalIndexationField,
        );
      });
    computedComputeIndexationFileBodyRequestParameterDTO = {
      ...computedComputeIndexationFileBodyRequestParameterDTO,
      reportageGlobalIndexation: reportageGlobalIndexationFieldsMappedForDTO,
    };
  }

  // Add reportage code if present
  if (!!reportageCode) {
    computedComputeIndexationFileBodyRequestParameterDTO = {
      ...computedComputeIndexationFileBodyRequestParameterDTO,
      reportageCode,
    };
  }

  // Add observations message if present
  if (!!reportageObservations) {
    computedComputeIndexationFileBodyRequestParameterDTO = {
      ...computedComputeIndexationFileBodyRequestParameterDTO,
      reportageObservations: reportageObservations,
    };
  }

  console.debug(
    "[computeIndexationFileBodyRequestParameterDTO] computedComputeIndexationFileBodyRequestParameterDTO",
    computedComputeIndexationFileBodyRequestParameterDTO,
  );

  return computedComputeIndexationFileBodyRequestParameterDTO;
}
