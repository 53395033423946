import { atom } from "recoil";
import { FIELD_TYPES, IndexationField } from "../../../models/IndexationField";
import { TreeFieldMode } from "@keepeek/refront-components";

/**
 * reportage code (computed by upload script, and communicated in the final mail to be able to update a reportage later)
 * ex : 2023-04-01_12-00-00-950
 */
export const reportageCodeAtom = atom<string>({
  key: "reportageCodeAtom",
  default: "",
});

/**
 * Is set to true when the reportage code query parameter is present
 */
export const isReportageCodePresentAtom = atom<boolean>({
  key: "isReportageCodePresentAtom",
  default: false,
});

export const DEBUT_DE_TOURNAGE_INTERNAL_NAME = "debut_tournage";
export const FIN_DE_TOURNAGE_INTERNAL_NAME = "fin_de_tournage";

export const REPORTAGE_GLOBAL_INDEXATION_DEFAULT_DATA: IndexationField[] = [
  {
    internalName: "title",
    type: FIELD_TYPES.TEXT,
    labelI18nKey: "upload-page-reportage-field-title",
    multiValued: false,
    required: true,
  },
  {
    internalName: "annee_de_la_video_de_reference",
    type: FIELD_TYPES.THESAURUS,
    labelI18nKey: "upload-page-reportage-field-annee_de_la_video_de_reference",
    multiValued: false,
    required: true,
  },
  {
    internalName: DEBUT_DE_TOURNAGE_INTERNAL_NAME,
    type: FIELD_TYPES.DATE,
    labelI18nKey: "upload-page-reportage-field-debut_tournage",
    multiValued: false,
    required: false,
  },
  {
    internalName: FIN_DE_TOURNAGE_INTERNAL_NAME,
    type: FIELD_TYPES.DATE,
    labelI18nKey: "upload-page-reportage-field-fin_de_tournage",
    multiValued: false,
    required: false,
  },
  {
    internalName: "lieux_geographiques",
    type: FIELD_TYPES.THESAURUS,
    labelI18nKey: "upload-page-reportage-field-lieux_geographiques",
    multiValued: true,
    required: true,
    modes: [TreeFieldMode.TREE_AUTOCOMPLETE, TreeFieldMode.TREE_VIEW],
  },
  {
    internalName: "realisateur",
    type: FIELD_TYPES.THESAURUS,
    labelI18nKey: "upload-page-reportage-field-realisateur",
    multiValued: true,
    required: false,
    modes: [TreeFieldMode.TREE_VIEW],
  },
  {
    internalName: "societe_de_production",
    type: FIELD_TYPES.THESAURUS,
    labelI18nKey: "upload-page-reportage-field-societe_de_production",
    multiValued: true,
    required: false,
    modes: [TreeFieldMode.TREE_VIEW],
  },
];

/**
 * Represents the data entered by the user when he creates a reportage
 */
export const reportageGlobalIndexationDataAtom = atom<IndexationField[]>({
  key: "reportageGlobalIndexationDataAtom",
  default: REPORTAGE_GLOBAL_INDEXATION_DEFAULT_DATA,
});

/**
 * Optional text for user to communicate with admin
 */
export const reportageObservationsAtom = atom<string>({
  key: "reportageObservationsAtom",
  default: "",
});
