import { IndexationField } from "../models/IndexationField";
import { useTranslation } from "react-i18next";

export type UseFieldsType = {
  computeIndexationFieldLabel: (indexationField: IndexationField) => string;
};

export const useIndexationFields = (): UseFieldsType => {
  console.debug("useIndexationFields");

  const { t } = useTranslation();

  /**
   *
   * Allow to return the label to display for specific field
   * If the field is required then a "*" will be added to that field
   *
   * @param indexationField
   */
  const computeIndexationFieldLabel = (indexationField: IndexationField): string => {
    let computedFieldLabel = t(indexationField?.labelI18nKey).toString();
    if (indexationField?.required) {
      computedFieldLabel += " *";
    }
    return computedFieldLabel;
  };

  return { computeIndexationFieldLabel: computeIndexationFieldLabel };
};
