import React, { FC } from "react";

import { CloudDone, InfoOutlined } from "@mui/icons-material";
import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import MessageWithIcon from "../MessageWithIcon";
import Loader from "../Loader";

export type DisplayEndSuccessMessagesProps = {
  isNotifyingServerOfCompletedUpload: boolean;
  /**
   * Could be used to display an error message if the request to the server ended with an error...
   * But not present in designs showed to the customer
   * And if this request end with an error (not probable), another deposit will start the upload process
   */
  isServerNotifiedOfCompletedUpload: boolean;
};

/**
 * Component to display final messages, depending on the status of the final server notification
 */
const DisplayEndSuccessMessages: FC<DisplayEndSuccessMessagesProps> = ({
  isNotifyingServerOfCompletedUpload,
}) => {
  console.debug("DisplayEndSuccessMessages");
  const { t } = useTranslation();

  return (
    <>
      <Loader
        sx={{
          visibility: isNotifyingServerOfCompletedUpload ? "visible" : "hidden",
        }}
      />
      {!isNotifyingServerOfCompletedUpload && (
        <Container sx={{ marginTop: "2rem" }}>
          <MessageWithIcon
            message={t("final-success-message-files-will-be-processed-info")}
            icon={<CloudDone />}
          />

          <MessageWithIcon
            message={t("final-success-message-automatic-behaviour-info")}
            icon={<InfoOutlined />}
          />

          <MessageWithIcon
            message={t("final-success-message-reportage-code-in-mail-notification-info")}
            icon={<InfoOutlined />}
          />
        </Container>
      )}
    </>
  );
};

export default DisplayEndSuccessMessages;
