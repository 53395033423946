import { TreeFieldMode } from "@keepeek/refront-components/dist/Fields/TreeField/types";

export enum FIELD_TYPES {
  TEXT = "TEXT",
  THESAURUS = "THESAURUS",
  DATE = "DATE",
}

export type ThesaurusValue = {
  id: number;
  label: string;
};

export type IndexationField = {
  internalName: string;
  type: FIELD_TYPES;
  labelI18nKey: string;
  multiValued: boolean;
  modes?: TreeFieldMode[];
  required: boolean;
  value?: string | ThesaurusValue[];
};
