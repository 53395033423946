import * as React from "react";
import { FC } from "react";

import { useAuthLogout } from "@keepeek/commons";
import { Logout } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import sanitizeHtml from "sanitize-html";

import { COLAS_BLACK, COLAS_WHITE, RESPONSIVE_BREAK_POINT_IN_PX } from "../../colasTheme";
import { configuration } from "../../configuration";

/**
 * Component designed to be on top of the screen, to display colas logo and logout button
 */
const ApplicationTopBar: FC = () => {
  const { t } = useTranslation();
  const { logout } = useAuthLogout();

  return (
    <AppBar
      position="sticky"
      sx={{
        paddingRight: "0 !important",
        backgroundColor: COLAS_WHITE,
        color: COLAS_BLACK,
        boxShadow: "none",
        "& .MuiToolbar-root": {
          paddingRight: 0
        }
      }}
    >
      <Toolbar
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gridTemplateRows: "1fr",
          gridTemplateGap: "0px",
          gridRowGap: "0px"
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h6"
            component="h1"
            sx={{
              flexGrow: 1,
              // span tag is present in label to be able to have the last in part in a second line in small devices
              "& span": { display: "inline-block" },
              [`@media (max-width: ${RESPONSIVE_BREAK_POINT_IN_PX}px)`]: {
                fontSize: "1rem",
                lineHeight: "1.2rem"
              }
            }}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(t("upload-page-title"), {
                  selfClosing: ["br", "span"]
                })
              }}
            />
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <img src={configuration.colasLogoImage} alt={"logo colas"} height={"65px"} />
        </Box>
        <Box
          sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", height: "100%" }}
        >
          <Button
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              borderRadius: 0,
              padding: "1rem",
              fontSize: "0.75rem",
              "&:hover": {
                "& svg, & span": {
                  color: `${COLAS_WHITE}`
                }
              },
              [`@media (max-width: ${RESPONSIVE_BREAK_POINT_IN_PX}px)`]: {
                padding: "0.5rem",
                fontSize: "0.6rem"
              }
            }}
            aria-label={t("upload-page-logout-button").toString()}
            onClick={() => logout()}
          >
            <Logout />
            <Typography variant="inherit" component="span" sx={{ marginTop: "0.5rem" }}>
              {t("upload-page-logout-button").toString()}
            </Typography>
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default ApplicationTopBar;
