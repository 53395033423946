export const DEVELOPMENT_ENV = "development";

/**
 * Allow to hide some console logs, only display errors messages in production env
 *
 * @param env The environment value retrieved from built in process.env.NODE_ENV
 */
export function configureLoggerForEnvironment(env: string) {
  if (DEVELOPMENT_ENV === env) {
    console.log(
      "%cDevelopment env : all logs are visible. In production mode only errors are displayed.",
      "color:#00ffa0; font-size: 15px"
    );
    // in development env we want to keep every logs
    return;
  }

  // In other environment we only display error messages
  console.debug = function() {
  };
  console.warn = function() {
  };
  console.log = function() {
  };
  console.info = function() {
  };
  console.dir = function() {
  };
  console.trace = function() {
  };
  console.count = function() {
  };
  console.group = function() {
  };
  console.assert = function() {
  };
  console.timeStamp = function() {
  };
  console.timeEnd = function() {
  };
  console.timeLog = function() {
  };
  console.trace = function() {
  };
  console.table = function() {
  };
  console.profile = function() {
  };
  console.profileEnd = function() {
  };
  console.groupCollapsed = function() {
  };
  console.dirxml = function() {
  };
}
