import { useSetRecoilState } from "recoil";
import { currentApplicationStepIndexAtom, reportageWorkflowAtom } from "../atoms";
import {
  azureFilesSelectedByUserToUploadAtom,
  azureFolderNameToUploadInAtom,
  getUniqueFolderNameWithTimestampToUploadInAndLogIt,
  isUploadInProgressAtom,
} from "../../azure/atoms";
import {
  REPORTAGE_GLOBAL_INDEXATION_DEFAULT_DATA,
  reportageCodeAtom,
  reportageGlobalIndexationDataAtom,
  reportageObservationsAtom,
} from "../../reportage/atoms";
import { isServerNotifiedOfCompletedUploadAtom } from "../../completedUploadNotifierForServer/atoms";

export type UseResetAppToPerformANewUploadType = {
  resetAppToPerformANewUpload: () => void;
};

export const useResetAppToPerformANewUpload = (): UseResetAppToPerformANewUploadType => {
  console.debug("useResetAppToPerformANewUpload");

  /**
   * Recoil states to reset to perform a new upload
   */
  const setCurrentApplicationStepIndex = useSetRecoilState(currentApplicationStepIndexAtom);
  const setAzureFilesSelectedByUserToUpload = useSetRecoilState(
    azureFilesSelectedByUserToUploadAtom,
  );
  const setAzureFolderNameToUploadIn = useSetRecoilState(azureFolderNameToUploadInAtom);
  const setIsUploadInProgress = useSetRecoilState(isUploadInProgressAtom);
  const setIsServerNotifiedOfCompletedUpload = useSetRecoilState(
    isServerNotifiedOfCompletedUploadAtom,
  );
  const setReportageWorkflow = useSetRecoilState(reportageWorkflowAtom);
  const setReportageGlobalIndexationData = useSetRecoilState(reportageGlobalIndexationDataAtom);
  const setReportageCode = useSetRecoilState(reportageCodeAtom);
  const setReportageObservationsAtom = useSetRecoilState(reportageObservationsAtom);

  const resetAppToPerformANewUpload = () => {
    console.debug("[useResetAppToPerformANewUpload] resetAppToPerformANewUpload");
    setCurrentApplicationStepIndex(0);
    setAzureFilesSelectedByUserToUpload([]);
    // Set a new folder name for the next deposit, take a look to the comment on the atom itself to know more
    setAzureFolderNameToUploadIn(getUniqueFolderNameWithTimestampToUploadInAndLogIt());
    setIsUploadInProgress(false);
    setIsServerNotifiedOfCompletedUpload(false);
    setReportageWorkflow(null);
    setReportageGlobalIndexationData(REPORTAGE_GLOBAL_INDEXATION_DEFAULT_DATA);
    setReportageCode("");
    setReportageObservationsAtom("");
  };

  return { resetAppToPerformANewUpload };
};
