import React, { FC } from "react";
import "filepond/dist/filepond.min.css";
import {
  azureFilesSelectedByUserToUploadAtom,
  PROXY_INDEXATION_FIELDS,
  UploadFile,
} from "../../../../providers/azure/atoms";
import { TableCell, TextField } from "@mui/material";
import InputFieldContainer from "../../ReportageGlobalIndexation/InputFieldContainer";
import { IndexationField } from "../../../../models/IndexationField";
import { FormProvider, useForm } from "react-hook-form";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  reportageWorkflowAtom,
  ReportageWorkflowId,
} from "../../../../providers/applicationStep/atoms";
import { COLAS_LIGHT_GREY } from "../../../../colasTheme";
import { updateIndexationFields } from "../../../../utils/fields";

export type ProxyIndexationFieldsContainerProps = {
  proxyFile: UploadFile;
};

/**
 * Component display indexation fields of a proxy file and update the state
 */
const ProxyIndexationFieldsContainer: FC<ProxyIndexationFieldsContainerProps> = ({ proxyFile }) => {
  console.debug("ProxyIndexationFieldsContainer");
  const reactHookFormMethods = useForm({ mode: "all" });
  const { control, reset } = reactHookFormMethods;
  const reportageWorkflow = useRecoilValue(reportageWorkflowAtom);
  const [azureFilesSelectedByUserToUpload, setAzureFilesSelectedByUserToUploadAtom] =
    useRecoilState(azureFilesSelectedByUserToUploadAtom);

  const handleChangedValue = (proxyFile: UploadFile, updatedField: IndexationField): void => {
    console.debug(
      "[ProxyIndexationFieldsContainer][handleChangedValue] proxyFile: %o, updatedField: %o.",
      proxyFile,
      updatedField,
    );

    const updatedProxyIndexationFields = updateIndexationFields(
      proxyFile?.proxyIndexationData || PROXY_INDEXATION_FIELDS,
      updatedField,
    );

    const updatedAzureFilesSelectedByUserToUpload = [...azureFilesSelectedByUserToUpload].map(
      (currentUploadFile) => {
        if (currentUploadFile.file.name === proxyFile?.file?.name) {
          const updatedUploadFile: UploadFile = {
            ...currentUploadFile,
            proxyIndexationData: updatedProxyIndexationFields,
          };
          return updatedUploadFile;
        } else {
          return currentUploadFile;
        }
      },
    );

    console.debug(
      "[ProxysIndexation][handleChangedValue] updatedAzureFilesSelectedByUserToUpload:",
      updatedAzureFilesSelectedByUserToUpload,
    );

    setAzureFilesSelectedByUserToUploadAtom(updatedAzureFilesSelectedByUserToUpload);
  };

  const handleMediaIdOnChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    reset();

    const newMediaId: number | undefined = parseInt(e.target.value) || undefined;

    // Only positive media id number
    if (newMediaId && newMediaId <= 0) {
      console.debug("[ProxysIndexation][handleMediaIdOnChange] only positive number are allowed.");
      return;
    }

    const updatedAzureFilesSelectedByUserToUpload = [...azureFilesSelectedByUserToUpload].map(
      (currentUploadFile) => {
        if (currentUploadFile.file.name === proxyFile?.file?.name) {
          const updatedUploadFile: UploadFile = {
            ...currentUploadFile,
            proxyMediaId: newMediaId,
          };
          if (!!newMediaId) {
            // Reset proxy indexation fields if the media id is specified since they won't be indexed in Keepeek
            return { ...updatedUploadFile, proxyIndexationData: PROXY_INDEXATION_FIELDS };
          }
          return updatedUploadFile;
        } else {
          return currentUploadFile;
        }
      },
    );
    console.debug(
      "[ProxysIndexation][handleMediaIdOnChange] updatedAzureFilesSelectedByUserToUpload:",
      updatedAzureFilesSelectedByUserToUpload,
    );
    setAzureFilesSelectedByUserToUploadAtom(updatedAzureFilesSelectedByUserToUpload);
  };

  return (
    <>
      {reportageWorkflow === ReportageWorkflowId.UPDATE_REPORTAGE && (
        <TableCell>
          <TextField
            sx={{ width: "100%" }}
            type={"tel"}
            onChange={handleMediaIdOnChange}
            value={proxyFile?.proxyMediaId || ""}
          />
        </TableCell>
      )}

      {PROXY_INDEXATION_FIELDS.map((proxyIndexationField) => {
        const currentProxyIndexationField =
          proxyFile?.proxyIndexationData?.find(
            (currentProxyIndexationField) =>
              currentProxyIndexationField?.internalName === proxyIndexationField?.internalName,
          ) || proxyIndexationField;
        return (
          <TableCell
            key={`${proxyFile?.file?.name}-${currentProxyIndexationField?.internalName}`}
            sx={
              !!proxyFile?.proxyMediaId
                ? {
                    backgroundColor: COLAS_LIGHT_GREY,
                  }
                : {}
            }
          >
            {/* When the media id is specified, we don't update the indexation of the media in Keepeek */}
            {!proxyFile?.proxyMediaId && (
              <FormProvider {...reactHookFormMethods}>
                <InputFieldContainer
                  indexationField={currentProxyIndexationField}
                  control={control}
                  displayLabel={false}
                  onValueChanged={(updatedField) => handleChangedValue(proxyFile, updatedField)}
                />
              </FormProvider>
            )}
          </TableCell>
        );
      })}
    </>
  );
};

export default ProxyIndexationFieldsContainer;
