import React, { FC } from "react";
import { Container, TextField } from "@mui/material";
import { IndexationField } from "../../../../models/IndexationField";

export type TextFieldProps = {
  indexationField: IndexationField;
  onValueChanged: (updatedField: IndexationField) => void;
};

/**
 * Component to display a TextField input
 * @param indexationField
 * @param onValueChanged callback method when the value have been updated
 */
const InputTextField: FC<TextFieldProps> = ({ indexationField, onValueChanged }) => {
  console.debug("InputTextField");

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const updatedField = { ...indexationField, value: e.target.value };
    onValueChanged(updatedField);
  };

  return (
    <Container className={"InputTextField"}>
      <TextField
        sx={{ width: "100%" }}
        type={"text"}
        onChange={handleOnChange}
        value={indexationField?.value || ""}
      />
    </Container>
  );
};

export default InputTextField;
