import axios from "axios";

import { configuration } from "../../../configuration";

/**
 * Represent the http request response, of the webservice call to check reportage code existence
 */
type ReportageCodeHttpResponseType = {
  reportageCodeExists: boolean;
};

/**
 * Perform a http request to the web service to check if the current reportage code exist or not
 * @param keycloakAuthorizationBearerToken
 * @param reportageCodeToCheck
 */
export async function checkReportageCodeExistenceFromWebService(
  keycloakAuthorizationBearerToken: string,
  reportageCodeToCheck: string
): Promise<boolean> {
  console.debug("checkReportageCodeExistenceFromWebService");

  let reportageCodeExistenceResponse;

  if (!keycloakAuthorizationBearerToken?.length) {
    console.warn(
      "[checkReportageCodeExistenceFromWebService]" +
      " keycloakAuthorizationBearerToken is not set."
    );
  }

  const webServiceUrlWithPath = configuration.backofficeUrl + "/addon/reportage-code";
  try {
    const response = await axios.get(webServiceUrlWithPath, {
      headers: { Authorization: `Bearer ${keycloakAuthorizationBearerToken}` },
      params: { reportageCode: reportageCodeToCheck }
    });
    console.debug("[checkReportageCodeExistenceFromWebService] raw response : ", response?.data);
    reportageCodeExistenceResponse = response?.data as ReportageCodeHttpResponseType;
  } catch (error: any) {
    console.error(
      "[checkReportageCodeExistenceFromWebService]" +
      " Error while checking reportage code existence. See Network to understand the problem."
    );
  }

  if (!!reportageCodeExistenceResponse?.reportageCodeExists) {
    console.debug(
      `[checkReportageCodeExistenceFromWebService] reportage code '${reportageCodeToCheck}' exists.`
    );
    return Promise.resolve(true);
  }

  console.debug(
    `[checkReportageCodeExistenceFromWebService] reportage code '${reportageCodeToCheck}' does not exist.`
  );

  return Promise.resolve(false);
}
