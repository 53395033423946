import {
  FunctionOverrideKey,
  FunctionOverrideParams,
  FunctionOverrideReturn,
  TreeOption,
} from "@keepeek/commons";
import { configuration } from "../configuration";

/**
 * This filter allow to display only some root thesaurus values (by id) for thesaurus configured in configuration file
 */
const componentThesaurusOverrideOptionsOut = {
  [FunctionOverrideKey.ComponentThesaurusOptionsOut]: (
    params: FunctionOverrideParams[FunctionOverrideKey.ComponentThesaurusOptionsOut],
  ): FunctionOverrideReturn[FunctionOverrideKey.ComponentThesaurusOptionsOut] => {
    const internalName = params.internalFieldName;
    const treeOption = params.treeOption;

    const rootThesaurusIdsToDisplayForCurrentField =
      configuration.rootThesaurusIdsToDisplayByThesaurusInternalName
        ?.filter((configuration) => configuration?.internalName === internalName)
        ?.flatMap((configuration) => configuration.rootThesaurusIdsToDisplay) || [];

    if (rootThesaurusIdsToDisplayForCurrentField?.length) {
      console.debug(
        `[componentThesaurusOptionsOut] custom filter for thesaurus '${internalName}', to only display theses root thesaurus ids: `,
        rootThesaurusIdsToDisplayForCurrentField,
      );
      const filteredTreeOption: TreeOption[] = treeOption?.filter((treeOption) =>
        rootThesaurusIdsToDisplayForCurrentField?.includes(treeOption.id),
      );
      return { treeOption: filteredTreeOption };
    }

    return { treeOption: params.treeOption };
  },
};

export default componentThesaurusOverrideOptionsOut;
