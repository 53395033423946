import { atom } from "recoil";

/**
 * Represent data retrieved from web service of an authenticated user
 */
export type AzureConfigurationForUser = {
  /**
   * The storage account name
   */
  accountName: string;
  /**
   * The container to upload files on
   */
  containerName: string;
  /**
   * The token with limited autorisations and have an expiration date
   * Will have to be fetched again (with a page refresh after expiration date, it is 24h today)
   */
  containerSasToken: string;
  /**
   * The name of the user's "fournisseur" (used to compute destination "folder" on Azure)
   */
  userFournisseur: string;
  /**
   * "Folder" to upload files in if "fournisseur" is not set for the user
   */
  defaultFolderNameIfFournisseurIsNotSet: string;
};

/**
 * To hold azure configuration of user, retrieved by webservice addon call
 */
export const azureConfigurationForUserAtom = atom<AzureConfigurationForUser>({
  key: "azureConfigurationForUser",
  default: {
    accountName: "",
    containerName: "",
    containerSasToken: "",
    userFournisseur: "",
    defaultFolderNameIfFournisseurIsNotSet: "",
  },
});
