import { IndexationField } from "../models/IndexationField";

/**
 *
 * @param indexationFields list of fields
 * @param newOrUpdatedIndexationField the new or updated fields to put in the array
 */
export function updateIndexationFields(
  indexationFields: IndexationField[],
  newOrUpdatedIndexationField: IndexationField,
): IndexationField[] {
  return indexationFields.map((currentField) => {
    if (currentField?.internalName === newOrUpdatedIndexationField?.internalName) {
      return {
        ...currentField,
        value: newOrUpdatedIndexationField?.value,
      };
    }
    return currentField;
  });
}
