import React, { FC, useEffect } from "react";
import { FieldThesaurusContainer, TreeFieldMode, TreeOption } from "@keepeek/refront-components";
import { useWatch } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";
import { Container } from "@mui/material";
import { KpkApiFormFieldType } from "@keepeek/api-client";
import { IndexationField, ThesaurusValue } from "../../../../models/IndexationField";
import { useTranslation } from "react-i18next";

export type InputThesaurusFieldProps = {
  control: Control;
  indexationField: IndexationField;
  multiple: boolean;
  onValueChanged: (updatedField: IndexationField) => void;
};

/**
 * Component to display a thesaurus picker with values fetched from backoffice
 *
 * @param control react-hook-form control
 * @param internalName of the field
 * @param multiple whether or not the field allows multiple values
 * @param onValueChanged callback method when the value have been updated
 */
const InputThesaurusField: FC<InputThesaurusFieldProps> = ({
  control,
  indexationField,
  multiple,
  onValueChanged,
}) => {
  console.debug("InputThesaurusField");

  const { t } = useTranslation();

  const thesaurusValues = useWatch({
    control,
    name: indexationField.internalName,
  });

  // // Sync value with Recoil state to be able to validate the step inside a Recoil selector like others steps
  useEffect(() => {
    console.debug(
      `[InputThesaurusField][useEffect/thesaurusValues] value changed for field ${indexationField.internalName}. Thesaurus values : `,
      thesaurusValues,
    );

    if (!thesaurusValues) {
      // When component have values at init (back to the previous step for instance)
      // We can have thesaurusValues undefined, and it uses to clear the input...
      // That way we prevent this issue...
      // Also, we are still able to clear the input by clicking on the thesaurus cross
      return;
    }

    console.debug("[InputThesaurusField][useEffect] indexationField", indexationField);
    const thesaurusValuesFromObjects: ThesaurusValue[] = thesaurusValues?.map(
      (field: TreeOption) => {
        return { id: field.id, label: field.label };
      },
    );
    const updatedField: IndexationField = {
      ...indexationField,
      value: thesaurusValuesFromObjects,
    };
    onValueChanged(updatedField);
    // eslint-disable-next-line
  }, [thesaurusValues]);

  return (
    <Container
      className={"InputThesaurusField"}
      // Do not display first block that can  contains a label next to the display choices switch
      // That way it is left aligned
      sx={{
        "& div[role='button']": { minHeight: "40px" },
        "& label": { display: "none" },
        // To avoid to hide the cross to clear result on the right
        "& .MuiAutocomplete-inputRoot": { paddingRight: "40px" },
      }}
    >
      <FieldThesaurusContainer
        formField={{
          id: indexationField?.internalName,
          type: KpkApiFormFieldType.Thesaurus,
          title: t(indexationField?.labelI18nKey),
          value: indexationField?.value || [],
          treeViewByDefault: true,
        }}
        multiple={multiple}
        modes={indexationField?.modes ? indexationField?.modes : [TreeFieldMode.TREE_AUTOCOMPLETE]}
        invertColors
      />
    </Container>
  );
};

export default InputThesaurusField;
