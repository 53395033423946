import React, { FC, ReactNode } from "react";

import { Box, Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import sanitizeHtml from "sanitize-html";

import { COLAS_BLACK, COLAS_WHITE } from "../../colasTheme";

export type SquareButtonWithIconProps = {
  message: string;
  icon: ReactNode;
  onClick: () => void;
};

/**
 * Component to display a large square button with an icon
 */
const SquareButtonWithIcon: FC<SquareButtonWithIconProps> = ({ message, icon, onClick }) => {
  console.debug("SquareButtonWithIcon");

  return (
    <Button
      onClick={onClick}
      sx={{
        backgroundColor: `${COLAS_WHITE}`,
        color: `${COLAS_BLACK}`,
        border: `1px solid ${COLAS_BLACK}`,
        minHeight: "15rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "0.5rem",
        "& div": {
          fontSize: "1rem"
        },
        "&:hover": {
          color: `${COLAS_WHITE}`
        }
      }}
    >
      <Box sx={{ "& svg": { width: "2rem", height: "2rem" } }}>{icon}</Box>
      <Box sx={{ marginTop: "0.5rem" }}>
        <Typography variant="caption" component="p">
          <span
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(message, {
                selfClosing: ["br"]
              })
            }}
          />
        </Typography>
      </Box>
    </Button>
  );
};

export default SquareButtonWithIcon;
