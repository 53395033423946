import React, { FC } from "react";

import { Box, Button, Container, Typography } from "@mui/material";
import sanitizeHtml from "sanitize-html";

export type AzureConfigurationForUserErrorProps = {
  errorMessageLabel: string;
  loading: boolean;
  handleRetryButtonClick: () => void;
  retryButtonLabel: string;
};

/**
 * Allow to display an error message to the user with the possibility to click a retry button
 *
 * @param errorMessageLabel error message to display to the user (html tags are accepted)
 * @param loading while it is true, the retry button will be disabled
 * @param handleRetryButtonClick action to perform when the retry button is clicked
 * @param retryButtonLabel
 */
const DisplayErrorMessageWithRetryButton: FC<AzureConfigurationForUserErrorProps> = ({
                                                                                       errorMessageLabel,
                                                                                       loading,
                                                                                       handleRetryButtonClick,
                                                                                       retryButtonLabel
                                                                                     }: AzureConfigurationForUserErrorProps) => {
  console.debug("DisplayErrorMessageWithRetryButton");

  return (
    <Box
      className={"DisplayErrorMessageWithRetryButton"}
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid #e0e0e0",
          padding: "2rem"
        }}
      >
        <Typography
          variant={"h3"}
          sx={{
            margin: 0,
            fontSize: "1.4rem",
            textAlign: "center"
          }}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(errorMessageLabel, {
                selfClosing: ["br"]
              })
            }}
          />
        </Typography>
        <Button
          disabled={loading}
          onClick={handleRetryButtonClick}
          sx={{
            marginTop: "2rem"
          }}
        >
          {retryButtonLabel}
        </Button>
      </Container>
    </Box>
  );
};

export default DisplayErrorMessageWithRetryButton;
