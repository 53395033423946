import React, { FC } from "react";
import { ErrorOutline, InfoOutlined } from "@mui/icons-material";
import { Container } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import MessageWithIcon from "../../MessageWithIcon";
import { useRecoilState, useRecoilValue } from "recoil";
import { reportageGlobalIndexationDataAtom } from "../../../providers/reportage/atoms";
import InputFieldContainer from "./InputFieldContainer";
import { areDebutDeTournageAndFinDeTournageDatesConsistentSelector } from "../../../providers/reportage/selectors";
import { COLAS_RED } from "../../../colasTheme";
import { IndexationField } from "../../../models/IndexationField";
import { updateIndexationFields } from "../../../utils/fields";

/**
 * Component to enter data global to the new reportage
 */
const ReportageGlobalIndexation: FC = () => {
  console.debug("ReportageGlobalIndexation");
  const { t } = useTranslation();
  const reactHookFormMethods = useForm({ mode: "all" });
  const { control } = reactHookFormMethods;
  const [reportageGlobalIndexationData, setReportageGlobalIndexationData] = useRecoilState(
    reportageGlobalIndexationDataAtom,
  );
  const areDebutDeTournageAndFinDeTournageDatesConsistent = useRecoilValue(
    areDebutDeTournageAndFinDeTournageDatesConsistentSelector,
  );
  console.debug(
    "[ReportageGlobalIndexation] reportageGlobalIndexationData: ",
    reportageGlobalIndexationData,
  );

  const handleChangedValue = (updatedField: IndexationField): void => {
    console.debug("[ReportageGlobalIndexation][handleChangedValue] updatedField:", updatedField);
    const updatedReportageGlobalIndexationFields = updateIndexationFields(
      reportageGlobalIndexationData,
      updatedField,
    );
    setReportageGlobalIndexationData(updatedReportageGlobalIndexationFields);
  };

  return (
    <Container
      className={"ReportageGlobalIndexation"}
      sx={{
        marginTop: "2rem",
        maxWidth: "60rem !important",
      }}
    >
      <MessageWithIcon
        message={t("upload-page-reportage-global-indexation-message")}
        icon={<InfoOutlined />}
      />

      {!areDebutDeTournageAndFinDeTournageDatesConsistent && (
        <MessageWithIcon
          message={t("upload-page-reportage-global-indexation-date")}
          icon={<ErrorOutline />}
          color={COLAS_RED}
        />
      )}

      <Container
        sx={{
          marginTop: "3rem",
          padding: "0 !important",
        }}
      >
        <FormProvider {...reactHookFormMethods}>
          {reportageGlobalIndexationData?.map((currentReportageGlobalIndexationField) => {
            return (
              <InputFieldContainer
                key={currentReportageGlobalIndexationField?.internalName}
                indexationField={currentReportageGlobalIndexationField}
                control={control}
                onValueChanged={handleChangedValue}
              />
            );
          })}
        </FormProvider>
      </Container>
    </Container>
  );
};

export default ReportageGlobalIndexation;
