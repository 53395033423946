import { formatByteSize } from "@theredhead/formatbytesize";

import { configuration } from "../configuration";

/**
 * Convert a size in bytes to a readable value with the appropriate unit selected
 * ex : 45946 -> 44.87KB
 *
 * @param sizeInBytesToConvert
 */
export function formatBytesToReadableValueWithUnit(
  sizeInBytesToConvert: number | undefined
): string {
  if (sizeInBytesToConvert === undefined) {
    console.warn("[formatBytesToReadableValueWithUnit] sizeInBytesToConvert is undefined.");
    return "";
  }
  return formatByteSize(sizeInBytesToConvert, { kilo: configuration.baseValueToCalculateFileSize });
}

export function getDateFormatted(date: Date | undefined): string {
  if (!date) {
    return "";
  }
  return `${date.toLocaleDateString()}  ${date.toLocaleTimeString()}`;
}
