import React, { FC, ReactNode } from "react";

import { Box, Container } from "@mui/material";
import Typography from "@mui/material/Typography";
import sanitizeHtml from "sanitize-html";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

export type MessageWithIconProps = {
  message: string;
  icon?: ReactNode;
  color?: string;
  sx?: SxProps<Theme>;
};

/**
 * Component to display a message with an icon next to it
 */
const MessageWithIcon: FC<MessageWithIconProps> = ({ message, icon, color, sx }) => {
  return (
    <Container sx={{ padding: "0 !important", ...sx }}>
      <Box sx={{ display: "flex", flexDirection: "row", marginBottom: "0.75rem" }}>
        {icon && (
          <Box
            sx={{
              "& svg": {
                height: "1.8rem",
                width: "1.8rem",
                display: "flex",
                justifyContent: "center",
                position: "relative",
                left: "-5px",
                color: color,
              },
            }}
          >
            {icon}
          </Box>
        )}
        <Box sx={{ display: "flex", alignItems: "center", marginLeft: !!icon ? "1rem" : 0 }}>
          <Typography
            sx={{
              fontSize: "1.3rem",
              lineHeight: "1.5rem",
              color: color,
            }}
            variant="caption"
            component="p"
          >
            <span
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(message, {
                  selfClosing: ["br"],
                }),
              }}
            />
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default MessageWithIcon;
