import React, { useEffect, useState } from "react";

import { keycloakInstanceAtom } from "@keepeek/commons";
import { Box, Button, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

import "filepond/dist/filepond.min.css";

import ApplicationStepper from "../components/ApplicationStepper";
import ApplicationTopBar from "../components/ApplicationTopBar";
import CreateOrUpdateReportage from "../components/CreateOrUpdateReportage";
import DisplayEndSuccessMessages from "../components/DisplayEndSuccessMessages";
import DisplayErrorMessageWithRetryButton from "../components/DisplayErrorMessageWithRetryButton";
import Loader from "../components/Loader";
import {
  currentApplicationStepIndexAtom,
  reportageWorkflowAtom,
  ReportageWorkflowId,
} from "../providers/applicationStep/atoms";
import { useApplicationStepper } from "../providers/applicationStep/hooks/useApplicationStepper";
import { useLoadAzureConfigurationForUser } from "../providers/azureConfigurationForUser/hooks/useLoadAzureConfigurationForUser";
import useNotifyServerOfCompletedUpload from "../providers/completedUploadNotifierForServer/hooks/useNotifyServerOfCompletedUpload";
import { reportageCodeAtom } from "../providers/reportage/atoms";
import { checkReportageCodeExistenceFromWebService } from "../providers/reportage/utils";
import { useResetAppToPerformANewUpload } from "../providers/applicationStep/hooks/useResetAppToPerformANewUpload";

export const REPORTAGE_CODE_QUERY_PARAMETER = "reportageCode";

function UploadPage() {
  console.debug("UploadPage");

  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const keycloakInstance = useRecoilValue(keycloakInstanceAtom);
  const setReportageWorkflow = useSetRecoilState(reportageWorkflowAtom);
  const setReportageCode = useSetRecoilState(reportageCodeAtom);

  const [isReportageCodeParameterChecking, setIsReportageCodeParameterChecking] = useState(false);
  const { isConfigurationLoading, isConfigurationLoaded, reloadAzureConfigurationForUser } =
    useLoadAzureConfigurationForUser({
      keycloakAuthorizationBearerToken: keycloakInstance?.token,
    });

  const {
    applicationSteps,
    currentApplicationStepIndex,
    isCurrentStepValid,
    isReportageWorkflowSelected,
    isStepperEnded,
    previousApplicationStep,
    nextApplicationStep,
  } = useApplicationStepper();

  const { resetAppToPerformANewUpload } = useResetAppToPerformANewUpload();

  const setCurrentApplicationStepIndex = useSetRecoilState(currentApplicationStepIndexAtom);

  const {
    notifyServerOfCompletedUpload,
    isNotifyingServerOfCompletedUpload,
    isServerNotifiedOfCompletedUpload,
  } = useNotifyServerOfCompletedUpload();

  useEffect(() => {
    console.debug("[UploadPage][useEffect] isStepperEnded");
    if (isStepperEnded && !isServerNotifiedOfCompletedUpload) {
      notifyServerOfCompletedUpload();
    }
  }, [notifyServerOfCompletedUpload, isServerNotifiedOfCompletedUpload, isStepperEnded]);

  useEffect(() => {
    console.debug("[UploadPage][useEffect] init");

    async function asyncCheckReportageCodeExistenceAndUpdateStates(
      keycloakToken: string,
      reportageCodeQueryParameterValue: string,
    ) {
      console.debug("[UploadPage] asyncCheckReportageCodeExistenceAndUpdateStates");

      setIsReportageCodeParameterChecking(true);

      const isReportageCodePresentResult = await checkReportageCodeExistenceFromWebService(
        keycloakToken,
        reportageCodeQueryParameterValue,
      );
      console.debug(
        `[UploadPage][asyncCheckReportageCodeExistenceAndUpdateStates] isReportageCodePresentResult: ${isReportageCodePresentResult}`,
      );

      if (isReportageCodePresentResult) {
        // We want to redirect the user to file section step to avoid having to ask him to click next step button
        setCurrentApplicationStepIndex(1);
      } else {
        // We redirect the user to the reportage code step to allow him to see its wrong and update it
        setCurrentApplicationStepIndex(0);
      }
      setIsReportageCodeParameterChecking(false);
    }

    if (searchParams.has(REPORTAGE_CODE_QUERY_PARAMETER)) {
      const reportageCodeQueryParameterValue =
        searchParams.get(REPORTAGE_CODE_QUERY_PARAMETER) || "";
      console.debug(
        `[UploadPage][useEffect][init] ${REPORTAGE_CODE_QUERY_PARAMETER} parameter is present with value: ${reportageCodeQueryParameterValue}`,
      );
      // We remove the parameter then to avoid con.fuse the user if he edits it and having to sync state with it
      searchParams.delete(REPORTAGE_CODE_QUERY_PARAMETER);
      setSearchParams(searchParams);
      console.debug(`[UploadPage][useEffect][init] ${REPORTAGE_CODE_QUERY_PARAMETER} removed.`);

      setReportageWorkflow(ReportageWorkflowId.UPDATE_REPORTAGE);
      setReportageCode(reportageCodeQueryParameterValue);

      asyncCheckReportageCodeExistenceAndUpdateStates(
        keycloakInstance?.token || "",
        reportageCodeQueryParameterValue,
      );
    } else {
      console.debug(
        `[UploadPage][useEffect][init] ${REPORTAGE_CODE_QUERY_PARAMETER} parameter is not present.`,
      );
    }
  }, [
    keycloakInstance?.token,
    searchParams,
    setCurrentApplicationStepIndex,
    setReportageCode,
    setReportageWorkflow,
    setSearchParams,
  ]);

  /**
   * Allow to reset Recoil state needed to perform a new upload
   */
  const handlePerformNewUploadButtonClick = (): void => {
    console.debug("[UploadPage] handlePerformNewUploadButtonClick");
    resetAppToPerformANewUpload();
  };

  if (isConfigurationLoading || isReportageCodeParameterChecking) {
    return <Loader />;
  }

  if (!isConfigurationLoaded) {
    return (
      <DisplayErrorMessageWithRetryButton
        errorMessageLabel={t("azure-configuration-for-user-error-message")}
        loading={isConfigurationLoading}
        handleRetryButtonClick={reloadAzureConfigurationForUser}
        retryButtonLabel={t("azure-configuration-for-user-retry-button")}
      />
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      {/* ApplicationTopBar and ApplicationStepper (if displayed) are always on top of page */}
      <Box sx={{ width: "100%", position: "sticky", top: 0, zIndex: 10 }}>
        <ApplicationTopBar />
        {isReportageWorkflowSelected && (
          <ApplicationStepper
            applicationSteps={applicationSteps}
            currentApplicationStepIndex={currentApplicationStepIndex}
            isCurrentStepValid={isCurrentStepValid}
            isStepperEnded={isStepperEnded}
            onPreviousApplicationStep={previousApplicationStep}
            onNextApplicationStep={nextApplicationStep}
          />
        )}
      </Box>

      {/*The create or update panel is displayed to choose what stepper is going to be used*/}
      {!isReportageWorkflowSelected && <CreateOrUpdateReportage />}

      {isReportageWorkflowSelected && !isStepperEnded && (
        <Box sx={{ width: "100%" }}>
          {applicationSteps[currentApplicationStepIndex]?.stepComponent}
        </Box>
      )}

      {isStepperEnded && (
        <DisplayEndSuccessMessages
          isNotifyingServerOfCompletedUpload={isNotifyingServerOfCompletedUpload}
          isServerNotifiedOfCompletedUpload={isServerNotifiedOfCompletedUpload}
        />
      )}

      <Container sx={{ marginTop: "2rem", display: "flex", justifyContent: "center" }}>
        {isStepperEnded && (
          <Button
            onClick={handlePerformNewUploadButtonClick}
            disabled={isNotifyingServerOfCompletedUpload}
          >
            {t("final-new-upload-button").toString()}
          </Button>
        )}
      </Container>
    </Box>
  );
}

export default UploadPage;
