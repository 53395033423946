import React, { FC, useEffect } from "react";

import { Box, Container } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useRecoilValue } from "recoil";

import { useApplicationStepper } from "../../../providers/applicationStep/hooks/useApplicationStepper";
import { useAzure } from "../../../providers/azure/hooks/useAzure";
import {
  azureFilesForUploadSortedSelector,
  azureFilesUploadGlobalStatsSelector,
} from "../../../providers/azure/selectors";
import LinearProgressWithLabel from "../../LinearProgressWithLabel";
import { useIndexationFile } from "../../../providers/indexationFile/hooks/useIndexationFile";
import Loader from "../../Loader";

/**
 * Component to display uploads
 */
const UploadManager: FC = () => {
  console.debug("UploadManager");
  const azureFilesValidForUploadSorted = useRecoilValue(azureFilesForUploadSortedSelector);
  const { nextApplicationStep } = useApplicationStepper();
  const { startUploading, uploadIsCompleted } = useAzure();
  const { globalProgress } = useRecoilValue(azureFilesUploadGlobalStatsSelector);
  const { computeIndexationFileAndUpdateState, isIndexationIndexationFileComputingInProcess } =
    useIndexationFile();

  // Start the upload when the step is displayed
  useEffect(() => {
    console.debug("[UploadManager] handleStartUpload");

    async function computeIndexationFileAndStartUpload() {
      await computeIndexationFileAndUpdateState();
      startUploading();
    }

    computeIndexationFileAndStartUpload().then(() =>
      console.debug("[UploadManager] computeIndexationFileAndStartUpload ended"),
    );

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // If the upload is done
    // complete the stepper by going to the next step
    if (uploadIsCompleted) {
      nextApplicationStep();
    }
  }, [uploadIsCompleted, nextApplicationStep]);

  if (isIndexationIndexationFileComputingInProcess) {
    return <Loader />;
  }

  return (
    <Container sx={{ marginTop: "2rem" }}>
      <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
        {/* display global progress */}
        {
          <Typography
            sx={{
              fontSize: "2rem",
              fontWeight: "bold",
              marginLeft: "0.5rem",
            }}
            variant="caption"
            component="p"
          >
            {`${Math.round(globalProgress)}%`}
          </Typography>
        }
      </Box>
      <Box sx={{ marginTop: "2rem" }}>
        {azureFilesValidForUploadSorted?.map((currentFile) => {
          return (
            <LinearProgressWithLabel
              sx={{ marginBottom: "1rem" }}
              key={currentFile.file.name}
              linearProgressProps={{ variant: "determinate", value: currentFile?.progress || 0 }}
              label={currentFile?.file?.name}
            />
          );
        })}
      </Box>
    </Container>
  );
};

export default UploadManager;
