import { atom } from "recoil";

/**

 * At the end of the upload, the application notify the server that a new upload is available
 * So the import script can be started
 *
 * This state, will be set to true when the server would have been notified
 * of the new upload
 *
 */
export const isServerNotifiedOfCompletedUploadAtom = atom<boolean>({
  key: "isServerNotifiedOfCompletedUploadAtom",
  default: false,
});
