import { selector } from "recoil";

import { azureFilesSelectedByUserToUploadAtom, FileType, UploadFile } from "../atoms";
import { configuration } from "../../../configuration";

export const azureFilesForUploadSortedSelector = selector<UploadFile[]>({
  key: "azureFilesForUploadSortedSelector",
  get: ({ get }) => {
    const azureFilesSelectedByUserToUpload = get(azureFilesSelectedByUserToUploadAtom);
    return (
      azureFilesSelectedByUserToUpload
        .filter((currentUploadFile) => !!currentUploadFile?.file?.name?.length)
        // Sort upload files by filename
        // to have them in same order in the upload manger that the order they're going to be uploaded
        .sort((uploadFileA, uploadFileB) => {
          if (uploadFileA?.file?.name < uploadFileB?.file?.name) {
            return -1;
          }
          if (uploadFileA?.file?.name > uploadFileB?.file?.name) {
            return 1;
          }

          return 0;
        })
    );
  },
});

export const isThereAtLeastOneFileToUploadSelector = selector<boolean>({
  key: "isThereAtLeastOneFileToUploadSelector",
  get: ({ get }) => {
    const azureFilesSelectedByUserToUpload = get(azureFilesSelectedByUserToUploadAtom);
    return !!azureFilesSelectedByUserToUpload?.length;
  },
});

export const areAllMasterFilesMappedToOneAndOnlyOneProxyFileSelector = selector<boolean>({
  key: "areAllMasterFilesMappedToOneProxyFileSelector",
  get: ({ get }) => {
    const azureFilesSelectedByUserToUpload = get(azureFilesSelectedByUserToUploadAtom);

    const isThereAnyUploadFileNotTaggedProxyOrMaster = azureFilesSelectedByUserToUpload?.some(
      (azureFileSelectedByUserToUpload) => azureFileSelectedByUserToUpload?.fileType === undefined,
    );

    if (isThereAnyUploadFileNotTaggedProxyOrMaster) {
      return false;
    }

    const masterFileNamesWithNumberOfTimesTheyAreMappedToAProxyFile: Map<string, number> =
      new Map();
    // Initialize every master file with 0 as number of times they are mapped to proxy file
    azureFilesSelectedByUserToUpload
      ?.filter((currentFile) => currentFile?.fileType === FileType.MASTER)
      ?.forEach((currentFile) => {
        masterFileNamesWithNumberOfTimesTheyAreMappedToAProxyFile.set(currentFile?.file?.name, 0);
      });
    // Iterate over proxy files and update the map to be able to know if every master files are mapped one and only one time
    azureFilesSelectedByUserToUpload
      ?.filter((currentFile) => currentFile?.fileType === FileType.PROXY)
      ?.forEach((currentFile) => {
        if (
          currentFile?.mappedMasterFile &&
          masterFileNamesWithNumberOfTimesTheyAreMappedToAProxyFile.has(
            currentFile.mappedMasterFile.file.name,
          )
        ) {
          const numberOfTimesThisMasterFileHaveMappedToAProxyFile =
            masterFileNamesWithNumberOfTimesTheyAreMappedToAProxyFile.get(
              currentFile.mappedMasterFile.file.name,
            );
          if (numberOfTimesThisMasterFileHaveMappedToAProxyFile === undefined) {
            console.error(
              `[areAllMasterFilesMappedToOneAndOnlyOneProxyFileSelector] the proxy: "${currentFile?.file?.name}" is mapped to a not existing master file: "${currentFile?.mappedMasterFile}"`,
            );
            return;
          }
          masterFileNamesWithNumberOfTimesTheyAreMappedToAProxyFile.set(
            currentFile.mappedMasterFile.file.name,
            numberOfTimesThisMasterFileHaveMappedToAProxyFile + 1,
          );
        }
      });

    console.debug(
      "[areAllMasterFilesMappedToOneAndOnlyOneProxyFileSelector] masterFileNamesWithNumberOfTimesTheyAreMappedToAProxyFile: %o",
      masterFileNamesWithNumberOfTimesTheyAreMappedToAProxyFile,
    );

    return !Array.from(masterFileNamesWithNumberOfTimesTheyAreMappedToAProxyFile.values()).some(
      (currentNumberOfTimesCurrentMasterHasBeenMapped) =>
        currentNumberOfTimesCurrentMasterHasBeenMapped === 0 ||
        currentNumberOfTimesCurrentMasterHasBeenMapped > 1,
    );
  },
});

export const proxyFilesSelector = selector<UploadFile[]>({
  key: "proxyFilesSelector",
  get: ({ get }) => {
    return get(azureFilesSelectedByUserToUploadAtom)?.filter(
      (currentFile) => currentFile?.fileType === FileType.PROXY,
    );
  },
});

/**
 * Return true if at least one proxy media id duplicate is found
 */
export const isThereDuplicateProxyMediaIdSelector = selector<boolean>({
  key: "isThereDuplicateProxyMediaIdSelector",
  get: ({ get }) => {
    const proxyFiles = get(proxyFilesSelector);
    const uniqueProxyMediaIds = new Set();
    proxyFiles
      ?.filter((proxyFile) => !!proxyFile?.proxyMediaId)
      ?.forEach((proxyFile) => uniqueProxyMediaIds.add(proxyFile?.proxyMediaId));

    const totalNumberOfProxyWithMediaIdSpecified = proxyFiles?.filter(
      (proxyFile) => !!proxyFile?.proxyMediaId,
    )?.length;

    return uniqueProxyMediaIds?.size !== totalNumberOfProxyWithMediaIdSpecified;
  },
});

/**
 * Return true if proxy or a master file have a forbidden extension according to the configuration
 */
export const isThereForbiddenExtensionSelector = selector<boolean>({
  key: "isThereForbiddenExtensionSelector",
  get: ({ get }) => {
    const azureFilesSelectedByUserToUpload = get(azureFilesSelectedByUserToUploadAtom);
    return azureFilesSelectedByUserToUpload?.some((currentFile) => {
      const extension = currentFile?.file?.name?.split(".")[1];

      let allowedExtensionsForCurrentFile: string[] = [];

      if (FileType.PROXY === currentFile?.fileType) {
        allowedExtensionsForCurrentFile = configuration.allowedProxyExtensions;
      } else if (FileType.MASTER === currentFile?.fileType) {
        allowedExtensionsForCurrentFile = configuration.allowedMasterExtensions;
      } else {
        return false;
      }

      return !allowedExtensionsForCurrentFile?.some(
        (currentAllowedExtension) =>
          currentAllowedExtension.toString().toUpperCase() === extension?.toUpperCase(),
      );
    });
  },
});

export type GlobalUploadStatsType = {
  globalProgress: number;
  numberOfFilesUploaded: number;
  numberOfFilesUploadingOrToUpload: number;
  totalNumberOfFiles: number;
};

export const azureFilesUploadGlobalStatsSelector = selector<GlobalUploadStatsType>({
  key: "azureFilesUploadGlobalStatsSelector",
  get: ({ get }) => {
    const azureFilesValidForUploadSorted = get(azureFilesForUploadSortedSelector);

    const totalNumberOfFiles = azureFilesValidForUploadSorted?.length;

    const globalProgress =
      azureFilesValidForUploadSorted?.reduce((progressSum, currentUploadFile) => {
        return progressSum + (currentUploadFile?.progress || 0);
      }, 0) / totalNumberOfFiles;

    const numberOfFilesUploaded = azureFilesValidForUploadSorted?.filter(
      (currentUploadFile) => currentUploadFile?.progress === 100,
    )?.length;

    const numberOfFilesUploading = azureFilesValidForUploadSorted?.filter(
      (currentUploadFile) =>
        currentUploadFile?.progress &&
        currentUploadFile.progress > 0 &&
        currentUploadFile.progress < 100,
    )?.length;

    const numberOfFilesUploadingOrToUpload = numberOfFilesUploaded + numberOfFilesUploading;

    return {
      globalProgress,
      numberOfFilesUploaded,
      numberOfFilesUploadingOrToUpload,
      totalNumberOfFiles,
    };
  },
});

/**
 * Return the list of  filenames of uploaded files
 */
export const azureFilenamesOfUploadedFilesSelector = selector<string[]>({
  key: "azureFilenamesOfUploadedFilesSelector",
  get: ({ get }) => {
    const azureFilesValidForUploadSorted = get(azureFilesForUploadSortedSelector);

    return azureFilesValidForUploadSorted
      ?.filter((currentUploadFile) => currentUploadFile?.progress === 100)
      .map((currentUploadFile) => currentUploadFile?.file?.name);
  },
});
