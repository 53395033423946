import React, { FC } from "react";

import { useAuthInit, useIsAuthenticated } from "@keepeek/commons";

import { configuration } from "./configuration";
import LoginPage from "./pages/LoginPage";
import UploadPage from "./pages/UploadPage";

const App: FC = () => {
  console.debug("App");
  useAuthInit(configuration.keycloakConfiguration, { disableSilentCheckSso: true });
  const { isAuthenticated } = useIsAuthenticated();
  console.debug("[App] isAuthenticated: ", isAuthenticated);

  return (
    // Bug on react 18 with StrictMode witch trigger a reload of the page.
    // That's why it's not set from index.tsx level.
    // https://github.com/react-keycloak/react-keycloak/issues/182
    <React.StrictMode>{isAuthenticated ? <UploadPage /> : <LoginPage />}</React.StrictMode>
  );
};

export default App;
