import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";

import InputFilesSelector from "../../../components/steps/InputFilesSelector";
import InputTextReportageCode from "../../../components/steps/InputTextReportageCode";
import ReportageGlobalIndexation from "../../../components/steps/ReportageGlobalIndexation";
import UploadManager from "../../../components/steps/UploadManager";
import {
  ApplicationStep,
  ApplicationStepId,
  applicationStepsAtom,
  currentApplicationStepIndexAtom,
  reportageWorkflowAtom,
  ReportageWorkflowId,
} from "../atoms";
import {
  isProxyMasterMappingStepValidSelector,
  isProxysIndexationStepValidSelector,
  isReportageCodeStepValidSelector,
  isReportageGlobalIndexationStepValidSelector,
  isSelectionStepValidSelector,
} from "../selectors";
import ProxyMasterMapping from "../../../components/steps/ProxyMasterMapping";
import ProxysIndexation from "../../../components/steps/ProxysIndexation";
import Observations from "../../../components/steps/Observations/Observations";

export type UseApplicationStepper = {
  applicationSteps: ApplicationStep[];
  currentApplicationStepIndex: number;
  isCurrentStepValid: boolean;
  isReportageWorkflowSelected: boolean;
  isStepperEnded: boolean;
  previousApplicationStep: () => void;
  nextApplicationStep: () => void;
};

/**
 * Custom hook to handle switch between components when navigating in the ApplicationStepper
 */
export const useApplicationStepper = (): UseApplicationStepper => {
  console.debug("useApplicationStepper");
  const { t } = useTranslation();
  const [reportageWorkflow, setReportageWorkflow] = useRecoilState(reportageWorkflowAtom);
  const [applicationSteps, setApplicationSteps] = useRecoilState(applicationStepsAtom);
  const [currentApplicationStepIndex, setCurrentApplicationStepIndex] = useRecoilState(
    currentApplicationStepIndexAtom,
  );
  const isReportageGlobalIndexationStepValid = useRecoilValue(
    isReportageGlobalIndexationStepValidSelector,
  );
  const isReportageCodeStepValid = useRecoilValue(isReportageCodeStepValidSelector);
  const isSelectionStepValid = useRecoilValue(isSelectionStepValidSelector);
  const isProxyMasterMappingStepValid = useRecoilValue(isProxyMasterMappingStepValidSelector);
  const isProxysIndexationStepValid = useRecoilValue(isProxysIndexationStepValidSelector);

  const previousApplicationStep = () => {
    if (currentApplicationStepIndex > 0) {
      const newApplicationStepIndex = currentApplicationStepIndex - 1;
      console.debug(
        "[useApplicationStepper][previousApplicationStep] newApplicationStepIndex: ",
        newApplicationStepIndex,
      );
      setCurrentApplicationStepIndex(newApplicationStepIndex);
    } else {
      console.debug("[useApplicationStepper][previousApplicationStep] reportageWorkflow reset.");
      setReportageWorkflow(null);
    }
  };

  const nextApplicationStep = () => {
    console.debug("[useApplicationStepper] nextApplicationStep");
    if (currentApplicationStepIndex < applicationSteps?.length) {
      setCurrentApplicationStepIndex(currentApplicationStepIndex + 1);
    }
  };

  /**
   * Set default value here to have access to the translation hook and set the value from here
   */
  useEffect(() => {
    console.debug("[useApplicationStepper][useEffect] init application steps");

    const REPORTAGE_GLOBAL_INDEXATION_STEP: ApplicationStep = {
      id: ApplicationStepId.REPORTAGE_GLOBAL_INDEXATION_STEP,
      name: t("upload-page-stepper-reportage-global-indexation-step"),
      validateStepButton: t("upload-page-stepper-reportage-global-indexation-validate-step-button"),
      stepComponent: <ReportageGlobalIndexation />,
    };

    const FILE_SELECTION_STEP: ApplicationStep = {
      id: ApplicationStepId.FILE_SELECTION_STEP,
      name: t("upload-page-stepper-files-selection-step"),
      validateStepButton: t("upload-page-stepper-files-selection-validate-step-button"),
      stepComponent: <InputFilesSelector />,
    };

    const PROXY_MASTER_MAPPING_STEP: ApplicationStep = {
      id: ApplicationStepId.PROXY_MASTER_MAPPING_STEP,
      name: t("upload-page-stepper-proxy-master-mapping-step"),
      validateStepButton: t("upload-page-stepper-proxy-master-mapping-validate-step-button"),
      stepComponent: <ProxyMasterMapping />,
    };

    const PROXYS_INDEXATION_STEP: ApplicationStep = {
      id: ApplicationStepId.PROXYS_INDEXATION_STEP,
      name: t("upload-page-stepper-proxys-indexation-step"),
      validateStepButton: t("upload-page-stepper-proxys-indexation-validate-step-button"),
      stepComponent: <ProxysIndexation />,
    };

    const MAIL_OBSERVATIONS_STEP: ApplicationStep = {
      id: ApplicationStepId.MAIL_OBSERVATIONS_STEP,
      name: t("upload-page-stepper-mail-observations-step"),
      validateStepButton: t("upload-page-stepper-mail-observations-validate-step-button"),
      stepComponent: <Observations />,
    };

    const UPLOAD_STEP: ApplicationStep = {
      id: ApplicationStepId.UPLOAD_STEP,
      name: t("upload-page-stepper-upload-step"),
      validateStepButton: t("upload-page-stepper-upload-validate-step-button"),
      stepComponent: <UploadManager />,
    };

    const REPORTAGE_NAME_STEP: ApplicationStep = {
      id: ApplicationStepId.REPORTAGE_CODE_STEP,
      name: t("upload-page-stepper-reportage-code-step"),
      validateStepButton: t("upload-page-stepper-reportage-code-validate-step-button"),
      stepComponent: <InputTextReportageCode />,
    };

    const createReportageSteps: ApplicationStep[] = [
      REPORTAGE_GLOBAL_INDEXATION_STEP,
      FILE_SELECTION_STEP,
      PROXY_MASTER_MAPPING_STEP,
      PROXYS_INDEXATION_STEP,
      MAIL_OBSERVATIONS_STEP,
      UPLOAD_STEP,
    ];

    const updateReportageSteps: ApplicationStep[] = [
      REPORTAGE_NAME_STEP,
      FILE_SELECTION_STEP,
      PROXY_MASTER_MAPPING_STEP,
      PROXYS_INDEXATION_STEP,
      MAIL_OBSERVATIONS_STEP,
      UPLOAD_STEP,
    ];

    // Define the workflow to the stepper
    switch (reportageWorkflow) {
      case ReportageWorkflowId.CREATE_REPORTAGE:
        setApplicationSteps(createReportageSteps);
        break;
      case ReportageWorkflowId.UPDATE_REPORTAGE:
        setApplicationSteps(updateReportageSteps);
        break;
    }

    // eslint-disable-next-line
  }, [reportageWorkflow]);

  const checkIfCurrentStepIsValid = (): boolean => {
    const currentStep = applicationSteps[currentApplicationStepIndex]?.id;
    let isCurrentStepValid = false;
    switch (currentStep) {
      case ApplicationStepId.REPORTAGE_GLOBAL_INDEXATION_STEP:
        isCurrentStepValid = isReportageGlobalIndexationStepValid;
        break;
      case ApplicationStepId.REPORTAGE_CODE_STEP:
        isCurrentStepValid = isReportageCodeStepValid;
        break;
      case ApplicationStepId.FILE_SELECTION_STEP:
        isCurrentStepValid = isSelectionStepValid;
        break;
      case ApplicationStepId.PROXY_MASTER_MAPPING_STEP:
        isCurrentStepValid = isProxyMasterMappingStepValid;
        break;
      case ApplicationStepId.PROXYS_INDEXATION_STEP:
        isCurrentStepValid = isProxysIndexationStepValid;
        break;
      case ApplicationStepId.MAIL_OBSERVATIONS_STEP:
        // Observation field is not mandatory
        isCurrentStepValid = true;
        break;
    }
    console.debug(
      `[useApplicationStepper][checkIfCurrentStepIsValid] for step ${currentStep} is ${isCurrentStepValid}`,
    );
    return isCurrentStepValid;
  };

  /**
   * Return true if the user have selected the reportage workflow (create or update)
   */
  const isReportageWorkflowSelected = (): boolean => {
    return reportageWorkflow !== null;
  };

  /**
   * When we reached the last step then we can't go back,
   * "this is the end !" (Skyfall, tu l'as ou tu l'as pas ? Tu l'as ?)
   */
  const isStepperEnded = (): boolean => {
    // Make sure before to compare the current step index with the total number of steps
    // that the list of steps have been initialized
    return applicationSteps?.length > 0 && applicationSteps?.length === currentApplicationStepIndex;
  };

  return {
    applicationSteps,
    currentApplicationStepIndex,
    isCurrentStepValid: checkIfCurrentStepIsValid(),
    isReportageWorkflowSelected: isReportageWorkflowSelected(),
    isStepperEnded: isStepperEnded(),
    previousApplicationStep,
    nextApplicationStep,
  };
};
