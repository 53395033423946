import { ReactNode } from "react";

import { atom } from "recoil";

export enum ApplicationStepId {
  REPORTAGE_GLOBAL_INDEXATION_STEP = "REPORTAGE_GLOBAL_INDEXATION_STEP",
  FILE_SELECTION_STEP = "FILE_SELECTION_STEP",
  PROXY_MASTER_MAPPING_STEP = "PROXY_MASTER_MAPPING_STEP",
  PROXYS_INDEXATION_STEP = "PROXYS_INDEXATION_STEP",
  MAIL_OBSERVATIONS_STEP = "MAIL_OBSERVATIONS_STEP",
  REPORTAGE_CODE_STEP = "REPORTAGE_CODE_STEP",
  UPLOAD_STEP = "UPLOAD_STEP",
}

export enum ReportageWorkflowId {
  CREATE_REPORTAGE = "CREATE_REPORTAGE",
  UPDATE_REPORTAGE = "UPDATE_REPORTAGE",
}

export type ApplicationStep = {
  id: ApplicationStepId;
  /**
   * Step name (displayed in the stepper)
   */
  name: string;
  /**
   * Component to display when the step have the focus
   */
  stepComponent: ReactNode;
  /**
   * Text displayed on button to click for going to next step
   */
  validateStepButton: string;
};

export const reportageWorkflowAtom = atom<ReportageWorkflowId | null>({
  key: "reportageWorkflowAtom",
  default: null
});

export const applicationStepsAtom = atom<ApplicationStep[]>({
  key: "applicationStepsAtom",
  default: []
});

export const currentApplicationStepIndexAtom = atom<number>({
  key: "currentApplicationStepIndexAtom",
  default: 0
});
