import { useCallback, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { configuration } from "../../../configuration";
import axios from "axios";
import { keycloakInstanceAtom } from "@keepeek/commons";
import {
  reportageCodeAtom,
  reportageGlobalIndexationDataAtom,
  reportageObservationsAtom,
} from "../../reportage/atoms";
import { proxyFilesSelector } from "../../azure/selectors";
import { azureFilesSelectedByUserToUploadAtom, FileType, UploadFile } from "../../azure/atoms";
import useRefreshKeycloakTokenIfExpired from "../../../hooks/useRefreshKeycloakTokenIfExpired";
import { computeIndexationFileBodyRequestParameterDTO } from "../utils/computeIndexationFileRequestBody";

export type useIndexationFileType = {
  /**
   * Allow to start the process of indexation file computing
   */
  computeIndexationFileAndUpdateState: () => Promise<void>;
  /**
   * True while the indexation file computing is in process
   */
  isIndexationIndexationFileComputingInProcess: boolean;
};

/**
 * This custom hook allow to compute an Excel indexation file and add it to the state of files to upload on Azure
 */
export const useIndexationFile = (): useIndexationFileType => {
  console.debug("useIndexationFile");

  const keycloakInstance = useRecoilValue(keycloakInstanceAtom);
  const { refreshTokenIfExpired } = useRefreshKeycloakTokenIfExpired();

  const reportageGlobalIndexationData = useRecoilValue(reportageGlobalIndexationDataAtom);
  const reportageCode = useRecoilValue(reportageCodeAtom);
  const reportageObservations = useRecoilValue(reportageObservationsAtom);
  const proxyFiles = useRecoilValue(proxyFilesSelector);
  const [
    isIndexationIndexationFileComputingInProcess,
    setIsIndexationIndexationFileComputingInProcess,
  ] = useState<boolean>(false);
  const [azureFilesSelectedByUserToUpload, setAzureFilesSelectedByUserToUpload] = useRecoilState(
    azureFilesSelectedByUserToUploadAtom,
  );

  const updateIndexationFileInAzureFilesToUploadState = (
    indexationFileUploadFile: UploadFile | undefined,
  ): void => {
    console.debug("[useIndexationFile] updateIndexationFileInAzureFilesToUploadState");
    const azureFilesToUploadWithoutIndexationFileUpdated = [
      ...azureFilesSelectedByUserToUpload,
    ]?.filter(
      (currentAzureFileToUpload) => currentAzureFileToUpload?.fileType !== FileType.INDEXATION_FILE,
    );

    let newAzureFilesToUploadWithIndexationFileUpdated = [
      ...azureFilesToUploadWithoutIndexationFileUpdated,
    ];

    // We only add the indexation file in state if it's defined (computed)
    if (!!indexationFileUploadFile) {
      newAzureFilesToUploadWithIndexationFileUpdated = [
        ...newAzureFilesToUploadWithIndexationFileUpdated,
        indexationFileUploadFile,
      ];
    }

    setAzureFilesSelectedByUserToUpload(newAzureFilesToUploadWithIndexationFileUpdated);
  };

  /**
   * Perform an async call to webservice for sending email to admins and the current user with the list of uploaded files
   */
  const computeIndexationFileAndUpdateState = useCallback(async () => {
    console.debug("[useIndexationFile] computeIndexationFile");

    updateIndexationFileInAzureFilesToUploadState(undefined);
    setIsIndexationIndexationFileComputingInProcess(true);

    await refreshTokenIfExpired();

    const webServiceUrlWithPath = configuration.backofficeUrl + "/addon/indexation-file";

    const requestBody = computeIndexationFileBodyRequestParameterDTO(
      reportageGlobalIndexationData,
      proxyFiles,
      reportageCode,
      reportageObservations,
    );

    let indexationFileSuccessfullyComputed;
    let response;
    try {
      response = await axios.post(webServiceUrlWithPath, requestBody, {
        headers: { Authorization: `Bearer ${keycloakInstance?.token}` },
        responseType: "arraybuffer",
      });
      indexationFileSuccessfullyComputed = !(response?.status < 200 || response?.status >= 300);
    } catch (error: any) {
      console.error(
        "[useIndexationFile][computeIndexationFile] Error computing indexation file. See Network to understand the problem.",
        error,
      );
      indexationFileSuccessfullyComputed = false;
    }

    if (!indexationFileSuccessfullyComputed) {
      return;
    }

    const responseFileData = response?.data;

    const excelContentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const computedIndexationFileBlobFromResponse = new Blob([responseFileData], {
      type: excelContentType,
    });

    const computedIndexationFileFromResponse = new File(
      [computedIndexationFileBlobFromResponse],
      "indexation_ok.xlsx",
      { type: excelContentType },
    );

    console.debug(
      "[useIndexationFile][computeIndexationFile] computedIndexationFileFromResponse",
      computedIndexationFileFromResponse,
    );

    const indexationFileToUpload: UploadFile = {
      file: computedIndexationFileFromResponse,
      fileType: FileType.INDEXATION_FILE,
    };

    updateIndexationFileInAzureFilesToUploadState(indexationFileToUpload);
    setIsIndexationIndexationFileComputingInProcess(false);

    // eslint-disable-next-line
  }, []);

  return {
    computeIndexationFileAndUpdateState,
    isIndexationIndexationFileComputingInProcess,
  };
};
