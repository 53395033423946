import { createTheme } from "@mui/material";
import * as locales from "@mui/material/locale";
import { frFR } from "@mui/material/locale";

export const COLAS_YELLOW = "#ffed00";
export const COLAS_YELLOW_WITH_A_LITTLE_TRANSPARENCY = `${COLAS_YELLOW}f5`;
export const COLAS_BLACK = "#000000";
export const COLAS_BLACK_LIGHT = "#00000080";
export const COLAS_WHITE = "#ffffff";
export const COLAS_LIGHT_GREY = "#f1f0ef";
export const COLAS_RED = "#ff2b2b";
export const COLAS_BLUE = "#2C4965";

export const RESPONSIVE_BREAK_POINT_IN_PX = 768;
export const RESPONSIVE_VERY_SMALL_DEVICES_BREAK_POINT_IN_PX = 440;

export const COLAS_FONT_FAMILY = ["Arial", "sans-serif"].join(", ");

const colasTheme = createTheme(
  {
    palette: {
      primary: {
        main: COLAS_BLACK,
      },
      secondary: {
        main: COLAS_YELLOW,
      },
    },
    typography: {
      fontFamily: COLAS_FONT_FAMILY,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            // To avoid having button without any-space between them in tiny devices
            marginLeft: "1px",
            fontSize: "1rem",
            padding: "1rem 5rem 1rem 5rem",
            boxShadow: "none !important",
            backgroundColor: COLAS_YELLOW,
            color: COLAS_BLACK,
            transition: "all 0.2s linear",
            "&:hover": {
              backgroundColor: COLAS_BLACK,
              color: COLAS_YELLOW,
            },
            "& div.MuiBox-root": {
              lineHeight: "0.8rem",
              "& span": {
                lineHeight: "0.8rem",
              },
            },
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          ".SnackbarItem-variantWarning": {
            backgroundColor: `${COLAS_YELLOW} !important`,
            color: `${COLAS_BLACK} !important`,
            fontSize: "1.1rem !important",
          },
        },
      },
    },
  },
  locales["frFR"],
);

export default colasTheme;
