import {
  DEBUT_DE_TOURNAGE_INTERNAL_NAME,
  FIN_DE_TOURNAGE_INTERNAL_NAME,
  reportageGlobalIndexationDataAtom,
} from "../atoms";
import { selector } from "recoil";
import { Dayjs } from "dayjs";
import { computeDateJsDateFromDateAsString } from "../../../components/steps/ReportageGlobalIndexation/InputDatePicker";

/**
 * Return true if debutDeTournage <= finDeTournage
 */
export const areDebutDeTournageAndFinDeTournageDatesConsistentSelector = selector<boolean>({
  key: "areDebutDeTournageAndFinDeTournageDatesConsistentSelector",
  get: ({ get }) => {
    const reportageGlobalIndexationData = get(reportageGlobalIndexationDataAtom);

    const debutDeTournageField = reportageGlobalIndexationData?.find(
      (currentField) => currentField?.internalName === DEBUT_DE_TOURNAGE_INTERNAL_NAME,
    );
    const finDeTournageField = reportageGlobalIndexationData?.find(
      (currentField) => currentField?.internalName === FIN_DE_TOURNAGE_INTERNAL_NAME,
    );

    let debutDeTournageAndFinDeTournageDatesAreConsistent = true;

    console.debug(
      "[areDebutDeTournageAndFinDeTournageDatesConsistentSelector] debutDeTournageField: %o, finDeTournageField: %o",
      debutDeTournageField,
      finDeTournageField,
    );

    if (debutDeTournageField?.value && finDeTournageField?.value) {
      const debutDeTournage: Dayjs | null = computeDateJsDateFromDateAsString(
        debutDeTournageField.value as string,
      );
      const finDeTournage: Dayjs | null = computeDateJsDateFromDateAsString(
        finDeTournageField.value as string,
      );

      if (!!debutDeTournage && !!finDeTournage) {
        debutDeTournageAndFinDeTournageDatesAreConsistent = debutDeTournage <= finDeTournage;
      }

      if (!debutDeTournageAndFinDeTournageDatesAreConsistent) {
        console.debug(
          "[areDebutDeTournageAndFinDeTournageDatesConsistentSelector] debutDeTournage year is > to finDeTournage year",
        );
      }
    }

    return debutDeTournageAndFinDeTournageDatesAreConsistent;
  },
});
