import React, { FC } from "react";

import { Box, LinearProgress } from "@mui/material";
import { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";

type LoaderProps = {
  sx?: SxProps<any>;
};

/**
 * Simple component to display a linear loader
 */
const Loader: FC<LoaderProps> = ({ sx }) => {
  console.debug("Loader");

  return (
    <Box sx={{ ...sx }} data-testid={"Loader"} className={"Loader"}>
      <LinearProgress
        sx={{
          height: "0.3rem",
          "& span": {
            height: "100%"
          }
        }}
      />
    </Box>
  );
};

export default Loader;
