import React, { FC, useRef } from "react";

import { Container, Link, Slide, Typography } from "@mui/material";
import { FilePondErrorDescription, FilePondFile } from "filepond/types";
import "filepond/dist/filepond.min.css";
import { useSnackbar } from "notistack";
import { FilePond } from "react-filepond";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";

import { COLAS_FONT_FAMILY, COLAS_RED } from "../../../colasTheme";
import { configuration } from "../../../configuration";
import { azureFilesSelectedByUserToUploadAtom, UploadFile } from "../../../providers/azure/atoms";
import { formatBytesToReadableValueWithUnit } from "../../../utils/conversion";
import MessageWithIcon from "../../MessageWithIcon";
import { InfoOutlined } from "@mui/icons-material";

/**
 * Component to select files and update recoil state
 */
const InputFilesSelector: FC = () => {
  console.debug("InputFilesSelector");
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const filePondRef = useRef(null);
  const [azureFilesSelectedByUserToUpload, setAzureFilesSelectedByUserToUpload] = useRecoilState(
    azureFilesSelectedByUserToUploadAtom,
  );
  const totalSizeOfAllFilesInBytes = azureFilesSelectedByUserToUpload
    .map((currentFile) => currentFile.file.size)
    .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

  const handleRemoveAllFilesButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    console.debug("[InputFilesSelector] handleRemoveAllFilesButtonClick");
    event.preventDefault();
    setAzureFilesSelectedByUserToUpload([]);
  };

  /**
   * FilePond component allow to select multiple files with the same filename,
   * but for our case, we can't have multiple file with the same filename
   * because we're uploading them in the same "folder" in Azure, no renaming performed.
   * The user will be informed about this.
   *
   * What we need to do here is to :
   * - get a list of unique file to update recoil state
   * - get the list of duplicates file to remove them from FilePond
   *  (case where a file already selected is selected a second time...)
   *
   * @param filePondFiles complete list of filePondFile already selected + the new selected ones
   */
  const handleOnUpdateFiles = (filePondFiles: FilePondFile[]) => {
    const uniqueFilePondFiles: FilePondFile[] = [];
    const duplicatesFilePondFilesToRemoveFromComponent: FilePondFile[] = [];

    filePondFiles.forEach((currentFilePondFile) => {
      const isCurrentFilePondFilePresent = uniqueFilePondFiles?.some(
        (currentUniqueFilePondFile) =>
          currentUniqueFilePondFile?.file?.name === currentFilePondFile?.file?.name,
      );

      if (isCurrentFilePondFilePresent) {
        duplicatesFilePondFilesToRemoveFromComponent.push(currentFilePondFile);
      } else {
        uniqueFilePondFiles.push(currentFilePondFile);
      }
    });

    const newAzureFilesSelectedByUserToUpload = uniqueFilePondFiles?.map((currentFilePondFile) => {
      return { file: currentFilePondFile?.file };
    }) as UploadFile[];
    console.debug(
      "[InputFilesSelector][handleOnUpdateFiles] newAzureFilesSelectedByUserToUpload: ",
      newAzureFilesSelectedByUserToUpload,
    );
    setAzureFilesSelectedByUserToUpload(newAzureFilesSelectedByUserToUpload);

    // Clean FilePond if duplicates filenames have been found
    if (duplicatesFilePondFilesToRemoveFromComponent?.length) {
      duplicatesFilePondFilesToRemoveFromComponent?.forEach((currentDuplicateFilePondFile) => {
        // @ts-ignore
        filePondRef.current.removeFile(currentDuplicateFilePondFile);
      });
      console.warn(
        "[InputFilesSelector][handleOnUpdateFiles] Two files can't have the same filename. Duplicates file removed : ",
        duplicatesFilePondFilesToRemoveFromComponent,
      );
      enqueueSnackbar(
        t("input-files-selector-multiples-files-with-same-filename-snackbar").toString(),
        {
          autoHideDuration: 5000,
          preventDuplicate: true,
          TransitionComponent: Slide,
          variant: "warning",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        },
      );
    }
  };

  const handleErrorOnFileAdded = (
    error: FilePondErrorDescription,
    file?: FilePondFile,
    status?: any,
  ) => {
    console.error("Error while loading file ", file?.filename, " with error code ", status?.code);
  };

  return (
    <Container
      className={"InputFilesSelector"}
      sx={{
        marginTop: "2rem",
        // Force font-family here for the FilePond component
        "& label, & div, & span": { fontFamily: COLAS_FONT_FAMILY },
        display: "flex",
        flexDirection: "row",
        maxWidth: "100% !important",
      }}
    >
      {/* Files selector */}
      <Container>
        {/* display the number of selected files and the total size in readable value */}
        {azureFilesSelectedByUserToUpload?.length ? (
          <Container sx={{ display: "flex", marginBottom: "0.5rem" }}>
            <Container
              sx={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.1rem",
                }}
                variant={"caption"}
                component={"p"}
              >
                {t("upload-page-total-number-of-files-and-size", {
                  count: azureFilesSelectedByUserToUpload?.length,
                  totalSize: formatBytesToReadableValueWithUnit(totalSizeOfAllFilesInBytes),
                }).toString()}
              </Typography>
            </Container>
            <Container
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Link href="#" color="inherit" onClick={handleRemoveAllFilesButtonClick}>
                <Typography
                  sx={{
                    fontSize: "1.1rem",
                  }}
                  variant={"caption"}
                  component={"span"}
                >
                  {t("upload-page-upload-remove-all-files-button").toString()}
                </Typography>
              </Link>
            </Container>
          </Container>
        ) : null}
        <FilePond
          ref={filePondRef}
          name="files"
          styleButtonRemoveItemPosition={"right"}
          // Files allow to set up components with files saved in state
          // when we switch from a step from another the component is cleaned by default
          files={azureFilesSelectedByUserToUpload.map((f) => f.file)}
          allowMultiple={true}
          labelIdle={t("upload-page-upload-input").toString()}
          onupdatefiles={handleOnUpdateFiles}
          onerror={handleErrorOnFileAdded}
          fileSizeBase={configuration.baseValueToCalculateFileSize}
          credits={false}
        />
      </Container>
      {/* Info messages */}
      <Container>
        <MessageWithIcon
          message={t("upload-page-info-message-1")}
          icon={<InfoOutlined />}
          color={COLAS_RED}
        />
        <MessageWithIcon
          message={t("upload-page-info-message-2")}
          icon={<InfoOutlined />}
          color={COLAS_RED}
        />
        <MessageWithIcon
          message={t("upload-page-info-message-3")}
          icon={<InfoOutlined />}
          color={COLAS_RED}
        />
        <MessageWithIcon
          message={t("upload-page-info-message-4")}
          icon={<InfoOutlined />}
          color={COLAS_RED}
        />
      </Container>
    </Container>
  );
};

export default InputFilesSelector;
