import { useCallback, useEffect, useState } from "react";

import { keycloakInstanceAtom } from "@keepeek/commons";
import { useRecoilState, useRecoilValue } from "recoil";

import { isReportageCodePresentAtom, reportageCodeAtom } from "../atoms";
import { checkReportageCodeExistenceFromWebService } from "../utils";

export type UseReportageCodeType = {
  isCheckingReportageCodeExistence: boolean;
  isReportageCodePresent: boolean;
};

export const useReportageCode = (): UseReportageCodeType => {
  console.debug("useReportageCode");
  const keycloakInstance = useRecoilValue(keycloakInstanceAtom);
  const [isCheckingReportageCodeExistence, setIsCheckingReportageCodeExistence] = useState(false);
  const [isReportageCodePresent, setIsReportageCodePresent] = useRecoilState(
    isReportageCodePresentAtom,
  );
  const reportageCode = useRecoilValue(reportageCodeAtom);

  const handleAsyncReportageCodeCheck = useCallback(
    async (currentReportageCodeToCheck: string): Promise<void> => {
      setIsReportageCodePresent(false);
      setIsCheckingReportageCodeExistence(true);
      const isReportageCodePresentResult = await checkReportageCodeExistenceFromWebService(
        keycloakInstance?.token || "",
        currentReportageCodeToCheck,
      );
      setIsCheckingReportageCodeExistence(false);
      setIsReportageCodePresent(isReportageCodePresentResult);
      console.debug(
        `[useReportageCode][useEffect] isReportageCodePresentResult: ${isReportageCodePresentResult}.`,
      );
    },
    [keycloakInstance?.token, setIsReportageCodePresent],
  );

  useEffect(() => {
    if (!reportageCode?.length) {
      // do not perform request if reportage code is empty
      return;
    }

    handleAsyncReportageCodeCheck(reportageCode);
  }, [handleAsyncReportageCodeCheck, reportageCode]);

  return {
    isCheckingReportageCodeExistence,
    isReportageCodePresent,
  };
};
