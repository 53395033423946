import { useCallback, useEffect, useState } from "react";

import { ContainerClient } from "@azure/storage-blob";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { azureConfigurationForUserAtom } from "../../azureConfigurationForUser/atoms";
import { fournisseurOrDefaultFolderNameSelector } from "../../azureConfigurationForUser/selectors";
import {
  azureFilesSelectedByUserToUploadAtom,
  azureFolderNameToUploadInAtom,
  isUploadInProgressAtom,
  UploadFile,
} from "../atoms";
import { azureFilesForUploadSortedSelector } from "../selectors";

export type UseAzureType = {
  /**
   * Trigger the isUploadInProgress state and begin the upload of files one after the other
   */
  startUploading: () => void;
  /**
   * True when the upload have been started and is ended
   */
  uploadIsCompleted: boolean;
};

export const useAzure = (): UseAzureType => {
  console.debug("useAzure");
  const azureConfigurationForUser = useRecoilValue(azureConfigurationForUserAtom);
  const fournisseurOrDefaultFolderName = useRecoilValue(fournisseurOrDefaultFolderNameSelector);
  const azureFolderNameToUploadIn = useRecoilValue(azureFolderNameToUploadInAtom);
  const setAzureFilesSelectedByUserToUpload = useSetRecoilState(
    azureFilesSelectedByUserToUploadAtom,
  );
  const azureFilesForUploadSorted = useRecoilValue(azureFilesForUploadSortedSelector);
  const [isUploadInProgress, setIsUploadInProgress] = useRecoilState(isUploadInProgressAtom);
  const [uploadIsCompleted, setUploadIsCompleted] = useState<boolean>(false);

  const computeBlobName = (
    fournisseurOrDefaultFolderName: string,
    azureFolderNameToUploadIn: string,
    filename: string,
  ): string => {
    return `${fournisseurOrDefaultFolderName}/${azureFolderNameToUploadIn}/${filename}`;
  };

  /**
   * Compute and return a ContainerClient with the containerName and the sas token
   */
  const getContainerClient = useCallback((): ContainerClient => {
    console.debug("[useAzure] getContainerClient");
    const containerUrlWithSasToken = `https://${azureConfigurationForUser.accountName}.blob.core.windows.net/${azureConfigurationForUser.containerName}?${azureConfigurationForUser.containerSasToken}`;
    console.debug(
      "[useAzure][getContainerClient] containerUrlWithSasToken: ",
      containerUrlWithSasToken,
    );
    return new ContainerClient(containerUrlWithSasToken);
  }, [
    azureConfigurationForUser.accountName,
    azureConfigurationForUser.containerName,
    azureConfigurationForUser.containerSasToken,
  ]);

  /**
   * Handle asynchronous upload when isUploadInProgress state say so
   */
  useEffect(() => {
    console.debug("[useAzure][useEffect] isUploadInProgress state changed.");
    if (isUploadInProgress) {
      const uploadFilesToAzure = async () => {
        const containerClient = getContainerClient();
        // While files are being uploaded, the onProgress callback method allow us to update the state,
        // and so the progress bar components
        // To avoid having the current new state of a single UploadFile
        // overriden while updating the state of the next one if the previous state is not finished (setState is async)
        // We keep the source of truth of the state here, updating it here synchronously, and passing it to the setState
        // That way if the setState is not finished,
        // the next one will not override the previous value since we pass the all state everytime
        let azureFilesForUploadSortedReferenceState = [...azureFilesForUploadSorted];
        for (const currentFileToUpload of azureFilesForUploadSorted) {
          const currentFileFutureBlobName = computeBlobName(
            fournisseurOrDefaultFolderName,
            azureFolderNameToUploadIn,
            currentFileToUpload?.file?.name,
          );
          console.debug(
            `[useAzure][useEffect][uploadFilesToAzure] start uploading : ${currentFileFutureBlobName}`,
          );
          const blockBlobClient = containerClient.getBlockBlobClient(currentFileFutureBlobName);
          await blockBlobClient.uploadData(currentFileToUpload.file, {
            // eslint-disable-next-line no-loop-func
            onProgress: (transferProgressEvent) => {
              const progress =
                (transferProgressEvent.loadedBytes / currentFileToUpload.file.size) * 100;
              // Update the source of truth before calling the setState with the up-to-date value to set in state
              azureFilesForUploadSortedReferenceState = computeNewAzureFilesForUploadReferenceState(
                azureFilesForUploadSortedReferenceState,
                currentFileToUpload,
                progress,
              );
              console.debug(
                `[useAzure][uploadFilesToAzure] ${currentFileToUpload?.file?.name} progress : ${progress}`,
              );
              setAzureFilesSelectedByUserToUpload(azureFilesForUploadSortedReferenceState);
            },
          });
        }
        setIsUploadInProgress(false);
        setUploadIsCompleted(true);
      };
      uploadFilesToAzure().catch((error) => console.error(error));
    }
    // eslint-disable-next-line
  }, [
    azureFolderNameToUploadIn,
    fournisseurOrDefaultFolderName,
    getContainerClient,
    isUploadInProgress,
    setAzureFilesSelectedByUserToUpload,
    setIsUploadInProgress,
  ]);

  /**
   * Return the computed new reference state with the progress updated for file
   *
   * @param azureFilesForUploadSortedReferenceState Current reference state
   * @param uploadFileToUpdateProgress The UploadFile to update progress to
   * @param progress new progress
   */
  const computeNewAzureFilesForUploadReferenceState = (
    azureFilesForUploadSortedReferenceState: UploadFile[],
    uploadFileToUpdateProgress: UploadFile,
    progress: number,
  ): UploadFile[] => {
    return [...azureFilesForUploadSortedReferenceState].map((currentUploadFile) =>
      currentUploadFile?.file?.name === uploadFileToUpdateProgress?.file?.name
        ? { ...currentUploadFile, progress }
        : { ...currentUploadFile },
    );
  };

  const startUploading = (): void => {
    console.debug("[useAzure] startUploading");
    setIsUploadInProgress(true);
  };

  return {
    startUploading,
    uploadIsCompleted,
  };
};
