import React, { FC } from "react";
import { Container, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import dayjs, { Dayjs } from "dayjs";
import AdapterDayjs from "@mui/lab/AdapterDayjs";
import { IndexationField } from "../../../../models/IndexationField";
import { useTranslation } from "react-i18next";
import "dayjs/locale/fr";
import { configuration } from "../../../../configuration";

export type InputDatePickerProps = {
  indexationField: IndexationField;
  onValueChanged: (updatedField: IndexationField) => void;
};

/**
 * Date format used to store the date and retrieve it
 * This is not the format used to display the value in the input
 * The display format is stored in the translations
 */
const INTERNAL_DATE_FORMAT = "DD/MM/YYYY";

export const computeDateJsDateFromDateAsString = (dateAsString: any): Dayjs | null => {
  if (!dateAsString) {
    return null;
  }
  const date = dayjs(dateAsString, INTERNAL_DATE_FORMAT);
  console.debug("[InputDatePicker][parseDate] date:", date);
  return date;
};

/**
 * Component to display a DatePicker
 *
 * @param indexationField
 * @param onValueChanged callback method when the value have been updated
 */
const InputDatePicker: FC<InputDatePickerProps> = ({ indexationField, onValueChanged }) => {
  console.debug("InputDatePicker");
  const { t } = useTranslation();
  const locale = configuration.defaultLocale;

  dayjs.locale(locale);

  const handleOnChange = (date: Dayjs | null) => {
    let newDateValueAsString = undefined;
    if (date) {
      try {
        console.debug("[InputDatePicker][handleOnChange] date: ", date);
        newDateValueAsString = date?.format(INTERNAL_DATE_FORMAT);
      } catch (err) {
        console.error("Can't convert date to ISO format", err);
      }
    }
    console.debug(`[InputDatePicker][handleOnChange] date: ${newDateValueAsString}`);
    const updatedField = { ...indexationField, value: newDateValueAsString };
    onValueChanged(updatedField);
  };

  return (
    <Container className={"InputDatePicker"}>
      <LocalizationProvider dateAdapter={AdapterDayjs} locale={locale}>
        <DatePicker
          inputFormat={t("input-date-format")}
          value={computeDateJsDateFromDateAsString(indexationField?.value)}
          onChange={handleOnChange}
          renderInput={(params) => (
            <TextField
              sx={{ width: "100%" }}
              type={"date"}
              autoComplete={"off"}
              {...params}
              helperText={null}
            />
          )}
        />
      </LocalizationProvider>
    </Container>
  );
};

export default InputDatePicker;
