import { useRecoilValue } from "recoil";
import { keycloakInstanceAtom, useAuthRefresh } from "@keepeek/commons";
import { configuration } from "../configuration";
import { useCallback } from "react";

export type UseRefreshKeycloakTokenIfExpiredType = {
  refreshTokenIfExpired: () => Promise<void>;
};

const useRefreshKeycloakTokenIfExpired = (): UseRefreshKeycloakTokenIfExpiredType => {
  console.debug("UseRefreshKeycloakTokenIfExpired");

  const keycloakInstance = useRecoilValue(keycloakInstanceAtom);
  const { refresh } = useAuthRefresh();

  const refreshTokenIfExpired = useCallback(async () => {
    /**
     * Token si configured with a life of ~10 minutes in keycloak
     * It's not enough for being sure that it will be still valid at the moment
     * So we refresh it if needed
     */
    if (keycloakInstance?.isTokenExpired(configuration.keycloakMinValidityInSeconds)) {
      console.warn("[useRefreshKeycloakTokenIfExpired] keycloak access token expired.");
      await refresh();
      console.debug(
        "[useRefreshKeycloakTokenIfExpired] keycloak access token refreshed : ",
        keycloakInstance.token,
      );
    }
    // eslint-disable-next-line
  }, []);

  return { refreshTokenIfExpired };
};

export default useRefreshKeycloakTokenIfExpired;
