import { useCallback, useEffect, useState } from "react";

import axios from "axios";
import { useSetRecoilState } from "recoil";

import { configuration } from "../../../configuration";
import { AzureConfigurationForUser, azureConfigurationForUserAtom } from "../atoms";

export type useAzureConfigurationForUserInit = {
  /**
   * true while the http request is not ended
   */
  isConfigurationLoading: boolean;
  /**
   * true only when the http request is ended with success
   */
  isConfigurationLoaded: boolean;
  /**
   * Allow to perform a new http request to retrieve the configuration
   */
  reloadAzureConfigurationForUser: () => void;
};

export type useAzureConfigurationForUserInitProps = {
  keycloakAuthorizationBearerToken: string | undefined;
};

/**
 * Allow to perform the HTTP request to load in state the azure configuration for an authenticated user.
 * Note : We don't use the Recoil selector async way to not having to deal with suspense for this data
 * Note : the sas token retrieved will be available for 24 only, the page will have to be reloaded to perform a new upload
 * @param keycloakAuthorizationBearerToken
 */
export const useLoadAzureConfigurationForUser = ({
  keycloakAuthorizationBearerToken,
}: useAzureConfigurationForUserInitProps): useAzureConfigurationForUserInit => {
  console.debug("UseLoadAzureConfigurationForUser");
  const [loading, setIsLoading] = useState<boolean>(true);
  const [isConfigurationLoaded, setIsConfigurationLoaded] = useState<boolean>(false);
  const setAzureConfigurationForUserState = useSetRecoilState(azureConfigurationForUserAtom);

  /**
   * Load an AzureConfigurationForUser object from an authenticated HTTP call to webservice in state
   * @param keycloakAuthorizationBearerToken
   */
  const loadAzureConfigurationForUserFromWebService = useCallback(
    async (keycloakAuthorizationBearerToken: string) => {
      console.debug(
        "[UseLoadAzureConfigurationForUser] loadAzureConfigurationForUserFromWebService",
      );

      let azureConfigurationForUser;

      if (!keycloakAuthorizationBearerToken) {
        console.warn(
          "[useLoadAzureConfigurationForUser][loadAzureConfigurationForUserFromWebService]" +
            " keycloakAuthorizationBearerToken is not set.",
        );
      }

      const webServiceUrlWithPath = configuration.backofficeUrl + "/addon/sas-token";
      try {
        const response = await axios.get(webServiceUrlWithPath, {
          headers: { Authorization: `Bearer ${keycloakAuthorizationBearerToken}` },
        });
        console.debug(
          "[useLoadAzureConfigurationForUser][loadAzureConfigurationForUserFromWebService] raw response : ",
          response?.data,
        );
        azureConfigurationForUser = response?.data as AzureConfigurationForUser;
      } catch (error: any) {
        console.error(
          "[useLoadAzureConfigurationForUser][loadAzureConfigurationForUserFromWebService]" +
            " Error retrieving azure configuration for user. See Network to understand the problem.",
        );
      }

      if (azureConfigurationForUser) {
        setAzureConfigurationForUserState(azureConfigurationForUser);
        setIsConfigurationLoaded(true);
      }

      setIsLoading(false);
    },
    [setAzureConfigurationForUserState],
  );

  const reloadAzureConfigurationForUser = () => {
    loadAzureConfigurationForUserFromWebService(keycloakAuthorizationBearerToken || "")
      .then(() =>
        console.debug("[useLoadAzureConfigurationForUser][reloadAzureConfigurationForUser] ended."),
      )
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    console.debug(
      `[useLoadAzureConfigurationForUser] keycloakAuthorizationBearerToken: ${keycloakAuthorizationBearerToken}`,
    );
    loadAzureConfigurationForUserFromWebService(keycloakAuthorizationBearerToken || "").then(() =>
      console.debug(
        "[useLoadAzureConfigurationForUser] azureConfigurationForUser retrieved from webservice.",
      ),
    );
  }, [keycloakAuthorizationBearerToken, loadAzureConfigurationForUserFromWebService]);

  return {
    isConfigurationLoading: loading,
    isConfigurationLoaded,
    reloadAzureConfigurationForUser,
  };
};
