import React, { FC } from "react";
import MessageWithIcon from "../../MessageWithIcon";
import { InfoOutlined } from "@mui/icons-material";
import { Container, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { reportageObservationsAtom } from "../../../providers/reportage/atoms";

/**
 * Component let comments on the upload if needed
 */
const Observations: FC = () => {
  console.debug("ProxysIndexation");
  const { t } = useTranslation();
  const [reportageObservations, setReportageObservations] =
    useRecoilState(reportageObservationsAtom);

  const handleValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.debug(
      "[Observations] handleValueChanged reportageObservations: ",
      reportageObservations,
    );
    setReportageObservations(e?.target?.value);
  };

  return (
    <Container
      className={"Observations"}
      sx={{
        marginTop: "2rem",
      }}
    >
      <MessageWithIcon
        message={t("upload-page-observations-info-message")}
        icon={<InfoOutlined />}
      />
      <TextField
        sx={{
          width: "100%",
          marginTop: "2rem",
        }}
        value={reportageObservations}
        onChange={handleValueChanged}
        variant="outlined"
        multiline={true}
        rows={10}
      />
    </Container>
  );
};

export default Observations;
