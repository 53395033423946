import { useCallback, useState } from "react";

import { keycloakInstanceAtom } from "@keepeek/commons";
import axios from "axios";
import { useRecoilState, useRecoilValue } from "recoil";

import { configuration } from "../../../configuration";
import { isServerNotifiedOfCompletedUploadAtom } from "../atoms";
import useRefreshKeycloakTokenIfExpired from "../../../hooks/useRefreshKeycloakTokenIfExpired";

export type UseNotifyServerOfCompletedUploadType = {
  /**
   * Allow to perform a new http request to notify the server of the new completed upload
   */
  notifyServerOfCompletedUpload: () => void;
  /**
   * True while the http request is not ended
   */
  isNotifyingServerOfCompletedUpload: boolean;
  /**
   * True only when the http request is ended with success
   */
  isServerNotifiedOfCompletedUpload: boolean;
};

const useNotifyServerOfCompletedUpload = (): UseNotifyServerOfCompletedUploadType => {
  console.debug("useNotifyServerOfCompletedUpload");

  const keycloakInstance = useRecoilValue(keycloakInstanceAtom);
  const { refreshTokenIfExpired } = useRefreshKeycloakTokenIfExpired();

  const [isServerNotifiedOfCompletedUpload, setIsServerNotifiedOfCompletedUpload] = useRecoilState(
    isServerNotifiedOfCompletedUploadAtom,
  );
  const [isNotifyingServerOfCompletedUpload, setIsNotifyingServerOfCompletedUpload] =
    useState<boolean>(false);

  /**
   * Perform an async call to webservice to notify the server of the new completed upload
   */
  const notifyServerOfCompletedUpload = useCallback(async () => {
    console.debug("[useNotifyServerOfCompletedUpload] notifyServerOfCompletedUpload");

    setIsNotifyingServerOfCompletedUpload(true);

    await refreshTokenIfExpired();

    const webServiceUrlWithPath = configuration.backofficeUrl + "/addon/new-completed-upload";
    let serverSuccessfullyNotified;
    try {
      const response = await axios.post(webServiceUrlWithPath, {
        headers: { Authorization: `Bearer ${keycloakInstance?.token}` },
      });
      serverSuccessfullyNotified = !(response?.status < 200 || response?.status >= 300);
    } catch (error: any) {
      console.error(
        "[useNotifyServerOfCompletedUpload][notifyServerOfCompletedUpload] Error notifying server of the new upload." +
          " See Network to understand the problem.",
        error,
      );
      serverSuccessfullyNotified = false;
    }

    setIsServerNotifiedOfCompletedUpload(serverSuccessfullyNotified);
    setIsNotifyingServerOfCompletedUpload(false);
    // eslint-disable-next-line
  }, [setIsNotifyingServerOfCompletedUpload]);

  return {
    notifyServerOfCompletedUpload,
    isNotifyingServerOfCompletedUpload,
    isServerNotifiedOfCompletedUpload,
  };
};

export default useNotifyServerOfCompletedUpload;
